import { ilkToAsset } from '@defisaver/tokens';
import { parseError } from '../services/utils';
import { parseCreationTransfer } from '../services/makerService';

const apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:7777'
  : 'https://defiexplore.com';

export const getGlobalInfo = () => async (dispatch) => {
  dispatch({ type: 'GLOBAL_INFO_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/stats/globalInfo`);
    const globalInfo = await res.json();
    dispatch({ type: 'GLOBAL_INFO_SUCCESS', payload: globalInfo });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'GLOBAL_INFO_ERROR', payload: parseError(err) });
  }
};

export const getActionsOverTimeData = () => async (dispatch) => {
  dispatch({ type: 'ACTIONS_OVER_TIME_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/stats/actionsOverTime`);
    const data = await res.json();
    data.actions = data.actions.map((a) => a
      .replace('-', ' ')
      .replace(/(\b[a-z])/g, (x) => x.toUpperCase())
      .replace('Dai', 'DAI'),
    );
    dispatch({ type: 'ACTIONS_OVER_TIME_SUCCESS', payload: data });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'ACTIONS_OVER_TIME_ERROR', payload: parseError(err) });
  }
};

export const getEthPriceOverTime = () => async (dispatch) => {
  dispatch({ type: 'ETH_PRICE_OVER_TIME_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/stats/ethPriceOverTime`);
    const data = await res.json();
    dispatch({ type: 'ETH_PRICE_OVER_TIME_SUCCESS', payload: data });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'ETH_PRICE_OVER_TIME_ERROR', payload: parseError(err) });
  }
};

export const getSimulationData = (ilk) => async (dispatch, getState) => {
  try {
    const alreadyCached = !!getState().general.cdpSimulationData[ilk]?.exchPrices?.length;
    if (alreadyCached) return;
    dispatch({ type: 'CDP_SIMULATION_DATA_REQUEST' });
    let fromBlock = 0;
    const asset = ilkToAsset(ilk);
    const _rates = await fetch(`${apiUrl}/api/maker/get-rates?type=${ilk}&fromBlock=${fromBlock}&toBlock=${'150000000'}`);
    const rates = await _rates.json();
    if (rates.length) fromBlock = rates[0][0] - 2000;
    const _makerPrices = await fetch(`${apiUrl}/api/maker/get-prices?type=${asset.toUpperCase()}&fromBlock=${fromBlock}&toBlock=${'150000000'}`);
    const makerPrices = await _makerPrices.json();
    const _exchPrices = await fetch(`${apiUrl}/api/otc/get-prices?type=${asset}&fromBlock=${fromBlock}&toBlock=${'150000000'}`);
    const exchPrices = await _exchPrices.json();
    if (!makerPrices.length && exchPrices.length) {
      // fill USDC maker prices
      const minBlock = exchPrices[0][0];
      const maxBlock = exchPrices[exchPrices.length - 1][0];
      for (let i = minBlock; i < maxBlock; i += 200) makerPrices.push([i, 1]);
    }
    dispatch({
      type: 'CDP_SIMULATION_DATA_SUCCESS',
      payload: {
        ilk, exchPrices, makerPrices, rates,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'CDP_SIMULATION_DATA_ERROR', payload: parseError(err) });
  }
};

export const getTopCdps = (pageNumber, pageSize, order, sortBy, filters) => async (dispatch) => {
  dispatch({ type: 'TOP_CDPS_REQUEST' });
  try {
    const res = await fetch(
      `${apiUrl}/api/topcdps?pageNumber=${pageNumber}&pageSize=${pageSize}&order=${order}&sortBy=${sortBy}&filters=${filters}`,
    );
    let topCpds = await res.json();
    topCpds = { ...topCpds, pageNumber: parseInt(topCpds.pageNumber, 10), total: parseInt(topCpds.total, 10) };
    dispatch({ type: 'TOP_CDPS_SUCCESS', payload: topCpds });
  } catch (err) {
    dispatch({ type: 'TOP_CDPS_ERROR', payload: parseError(err) });
  }
};

export const changeSearchQuery = (query) => ({ type: 'SEARCH_DATA_QUERY', payload: query });

export const markDataAsObsolete = () => ({ type: 'SEARCH_DATA_OBSOLETE' });

export const clearSearchData = () => ({ type: 'SEARCH_DATA_CLEAR' });

export const getCdpData = (cdpId, order) => async (dispatch) => {
  dispatch(changeSearchQuery(cdpId));
  dispatch({ type: 'CDP_DATA_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/cdps/${cdpId}?order=${order}`);
    const payload = await res.json();
    const lastAction = payload.history[payload.history.length - 1];
    const secondLastAction = payload.history[payload.history.length - 2];
    if (lastAction?.actionType === 'transfer' && secondLastAction?.actionType === 'create') {
      const creationContract = parseCreationTransfer(lastAction);
      if (creationContract) {
        secondLastAction.actionInfo = creationContract;
        payload.history.pop();
      }
    }
    dispatch({ type: 'CDP_DATA_SUCCESS', payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'CDP_DATA_ERROR', payload: parseError(err) });
  }
};

export const getAddressData = (address, orderTx, order, sortBy) => async (dispatch) => {
  dispatch(changeSearchQuery(address));
  dispatch({ type: 'ADDRESS_DATA_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/users/${address}?orderTx=${orderTx}&order=${order}&sortBy=${sortBy}`);
    const payload = await res.json();
    dispatch({ type: 'ADDRESS_DATA_SUCCESS', payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'ADDRESS_DATA_ERROR', payload: parseError(err) });
  }
};

export const getTxData = (txHash) => async (dispatch) => {
  dispatch(changeSearchQuery(txHash));
  dispatch({ type: 'TX_DATA_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/tx/${txHash}`);
    const payload = await res.json();
    dispatch({ type: 'TX_DATA_SUCCESS', payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'TX_DATA_ERROR', payload: parseError(err) });
  }
};

export const parseUrl = () => {
  const path = document.location.pathname.substr(1);
  if (path.substr(0, 3) === 'cdp') return { type: 'cdp', cdpId: path.substr(4).replace(/[^\d]/g, '') };
  if (path.substr(0, 2) === 'tx') return { type: 'tx', txHash: path.substr(3).replace(/[^0-9a-zA-Z]/g, '') };
  if (path.substr(0, 7) === 'address') return { type: 'address', address: path.substr(8).replace(/[^\d\w.]/g, '') };
  return { type: 'root' };
};

export const getLiqudationsData = (filter = 'any', page = 1) => async (dispatch) => {
  dispatch({ type: 'LIQUIDATIONS_REQUEST' });
  dispatch({ type: 'LIQUIDATIONS_CHANGE_QUERY', payload: { filter, page } });
  try {
    const res = await fetch(`${apiUrl}/api/liquidations/${filter}/${page}`);
    const payload = await res.json();
    dispatch({ type: 'LIQUIDATIONS_SUCCESS', payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'LIQUIDATIONS_ERROR', payload: parseError(err) });
  }
};

export const getLiqudationData = (cdpType, liqId, biteHash) => async (dispatch) => {
  dispatch({ type: 'LIQUIDATION_REQUEST' });
  try {
    const res = await fetch(`${apiUrl}/api/liquidation/id/${cdpType}/${liqId}/${biteHash}`);
    const payload = await res.json();
    dispatch({ type: 'LIQUIDATION_SUCCESS', payload });
  } catch (err) {
    console.error(err);
    dispatch({ type: 'LIQUIDATION_ERROR', payload: parseError(err) });
  }
};
