import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './AutomationShield.scss';

class AutomationShield extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { active, width = 45, height = 45 } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="automation-shield-icon">
        <g className="automation-shield-icon-bg" filter={active ? 'url(#filter0_autom_shield)' : ''}>
          <rect x="8" y="8" width="29" height="29" rx="14.5" fill={active ? '#37B06F' : '#61717E'} />
        </g>
        <g className="automation-shield-icon-shield" clipPath="url(#clip0)" filter="url(#filter1_autom_shield)" opacity={active ? 1 : 0.3}>
          <path d="M30.6367 18.1206L30.6361 18.1057C30.6278 17.9217 30.6222 17.7271 30.6189 17.5106C30.6032 16.4546 29.7638 15.5812 28.7077 15.5222C26.5059 15.3993 24.8025 14.6813 23.3471 13.2627L23.3347 13.2509C22.86 12.8156 22.1439 12.8156 21.6691 13.2509L21.6566 13.2627C20.2012 14.6813 18.4979 15.3993 16.296 15.5223C15.2401 15.5812 14.4005 16.4546 14.3849 17.5107C14.3817 17.7258 14.376 17.9204 14.3677 18.1057L14.3668 18.1403C14.324 20.3856 14.2708 23.1799 15.2056 25.7162C15.7196 27.111 16.4981 28.3234 17.5193 29.32C18.6823 30.455 20.2056 31.3561 22.0469 31.9981C22.1068 32.019 22.169 32.0359 22.2325 32.0486C22.3219 32.0665 22.4119 32.0754 22.5019 32.0754C22.5919 32.0754 22.682 32.0665 22.7713 32.0486C22.8347 32.0359 22.8974 32.0188 22.9576 31.9978C24.7967 31.3546 26.3184 30.4531 27.4804 29.3183C28.5011 28.3214 29.2796 27.1087 29.7941 25.7136C30.7324 23.1697 30.6793 20.3701 30.6367 18.1206ZM28.7397 25.3248C27.7538 27.998 25.7411 29.8339 22.5868 30.937C22.5755 30.9408 22.5635 30.9442 22.5511 30.9467C22.5185 30.9531 22.4854 30.9531 22.4524 30.9465C22.4401 30.944 22.4281 30.9408 22.417 30.937C19.2593 29.8359 17.2453 28.0011 16.2601 25.3277C15.3985 22.9898 15.4473 20.4247 15.4904 18.1618L15.4907 18.1492C15.4994 17.9547 15.5053 17.7511 15.5085 17.5272C15.5155 17.0584 15.889 16.6704 16.3587 16.6443C17.6047 16.5747 18.6994 16.3339 19.7052 15.9082C20.7097 15.483 21.6018 14.8837 22.4323 14.076C22.4746 14.0403 22.5294 14.0402 22.5714 14.076C23.4021 14.8837 24.2942 15.483 25.2986 15.9082C26.3044 16.3339 27.399 16.5747 28.6452 16.6443C29.115 16.6704 29.4884 17.0584 29.4953 17.5274C29.4986 17.7524 29.5045 17.9561 29.5132 18.1492C29.5562 20.4148 29.6038 22.9819 28.7397 25.3248Z" fill="white" />
          <path d="M22.5012 17.7275C19.8695 17.7275 17.7285 19.8686 17.7285 22.5002C17.7285 25.132 19.8695 27.2731 22.5012 27.2731C25.1328 27.2731 27.2739 25.132 27.2739 22.5002C27.2739 19.8686 25.1328 17.7275 22.5012 17.7275ZM22.5012 26.1492C20.4891 26.1492 18.8522 24.5123 18.8522 22.5002C18.8522 20.4881 20.4891 18.8513 22.5012 18.8513C24.5131 18.8513 26.15 20.4881 26.15 22.5002C26.15 24.5123 24.5131 26.1492 22.5012 26.1492Z" fill="white" />
          <path d="M20.627 24.1752L21.3413 22.7465M24.3541 24.1752L23.6397 22.7465M21.3413 22.7465L21.5587 22.3117L22.4905 20.4481L23.4223 22.3117L23.6397 22.7465M21.3413 22.7465H23.6397" stroke="white" strokeWidth="0.704643" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <filter id="filter0_autom_shield" x="0.339623" y="0.339623" width="44.3208" height="44.3208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="3.83019" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.216929 0 0 0 0 0.689956 0 0 0 0 0.434522 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter id="filter1_autom_shield" x="11.7904" y="11.7911" width="21.4178" height="21.4178" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="0.566712" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect width="19.1509" height="19.1509" fill="white" transform="translate(12.9238 12.9246)" />
          </clipPath>
        </defs>
      </svg>

    );
  }
}


AutomationShield.propTypes = {
  active: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AutomationShield;
