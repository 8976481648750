import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import TooltipWrapper from '../../../Decorative/TooltipWrapper/TooltipWrapper';
import CdpRatioCircle from '../../../Decorative/RatioCircle/CdpRatioCircle';
import { defined, formatNumberWithInf, nFormatter } from '../../../../services/utils';

import './DataItem.scss';

const DataItem = ({
  label, value, symbol, loading, showRatioCircle, valueColor, symbolAfter,
  highlighted, active, valueAddon, additionalValue, additionalValueLabel, additionalValueTooltip,
  additionalValueIcon, smallSymbol, tooltip, description, decimals, type, ratioCircleMin, ratioCircle,
  showInfinity, alwaysShowAfter, textValue, ...props
}) => (
  <>
    <div className={`data-item-wrapper ${type} ${highlighted ? 'highlighted' : ''} ${active ? 'active' : ''}`} {...props}>
      <div className="label-value-wrapper">
        <div className="label-wrapper">
          { description && (<TooltipWrapper title={description}><i className="icon icon-Info-circle" /></TooltipWrapper>) }
          { showRatioCircle && <CdpRatioCircle ratio={ratioCircle || value} min={ratioCircleMin} /> }
          <span className="label">
            {label && label}{label && ((!label.substr || label.substr(label.length - 1) === ':') ? '' : ':')}
          </span>
        </div>

        <div className="value-wrapper" style={{ color: valueColor }}>
          { loading && t('common.loading') }

          {
              (!loading && defined(value)) && (
                textValue
                  ? (
                    <TooltipWrapper copy title={tooltip || value} useText>
                      {value}
                    </TooltipWrapper>
                  )
                  : (
                    <TooltipWrapper copy title={tooltip || value} useText={!!tooltip}>
                      {parseFloat(value) < 0 && '-'}
                      { symbol && !symbolAfter && <span className={`symbol ${smallSymbol && 'small'}`}>{symbol}</span>}
                      { (showInfinity ? formatNumberWithInf : nFormatter)(Math.abs(value), decimals) }
                      { symbol && symbolAfter && <span className={`symbol ${smallSymbol && 'small'}`}>{symbol}</span>}
                    </TooltipWrapper>
                  )
              )
            }

          { valueAddon && !loading && (valueAddon) }
        </div>
      </div>

      {
          !!additionalValue && (
            <div className="after-value">
              <div className="amount-wrapper">
                <span className="after">{additionalValueLabel}:</span>
                <span className="amount">
                  <TooltipWrapper title={additionalValueTooltip || additionalValue} copy={!additionalValueTooltip}>
                    {additionalValueIcon} { `${nFormatter(additionalValue, decimals)}${symbol}` }
                  </TooltipWrapper>
                </span>
              </div>
            </div>
          )
        }
    </div>
    { type === 'standard' && <div className="spacer" />}
  </>
);

DataItem.defaultProps = {
  loading: false,
  decimals: 2,
  type: 'standard',
  showRatioCircle: false,
  ratioCircle: 0,
  ratioCircleMin: '0',
  description: '',
  highlighted: false,
  active: false,
  symbolAfter: true,
  valueColor: '',
  valueAddon: '',
  additionalValue: '',
  additionalValueTooltip: '',
  additionalValueLabel: '',
  additionalValueIcon: '',
  smallSymbol: false,
  tooltip: '',
  showInfinity: false,
  alwaysShowAfter: false,
  label: '',
  symbol: '',
  textValue: false,
};

DataItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  symbol: PropTypes.string,
  decimals: PropTypes.number,
  loading: PropTypes.bool,
  description: PropTypes.string,
  type: PropTypes.string,

  symbolAfter: PropTypes.bool,
  valueColor: PropTypes.string,
  showRatioCircle: PropTypes.bool,
  ratioCircle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratioCircleMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlighted: PropTypes.bool,
  active: PropTypes.bool,
  valueAddon: PropTypes.node,
  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalValueTooltip: PropTypes.string,
  additionalValueLabel: PropTypes.string,
  additionalValueIcon: PropTypes.node,
  smallSymbol: PropTypes.bool,
  tooltip: PropTypes.string,
  showInfinity: PropTypes.bool,
  alwaysShowAfter: PropTypes.bool,
  textValue: PropTypes.bool,
};

export default DataItem;
