import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import MakerManageDataItems from './MakerManageDataItems/MakerManageDataItems';
import MakerManageMcdInfoItems from './MakerManageMcd/MakerManageMcdInfoItems/MakerManageMcdInfoItems';
import MakerManageHeader from './MakerManageHeader/MakerManageHeader';
import HistoryTable from '../HistoryTable/HistoryTable';
import ManageCdpPanel from '../ManageCdpPanel/ManageCdpPanel';
import NotFound from '../NotFound/NotFound';
import MakerManageProfitCalc from './MakerManageProfitCalc/MakerManageProfitCalc';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import CdpHistoryGraph from '../CdpHistoryGraph/CdpHistoryGraph';

import '../../common/dashboard-page.scss';
import './MakerManage.scss';
import { getCdpData } from '../../actions/apiActions';

const MakerManage = (props) => {
  const { cdp, searchDataObsolete, searchDataLoading } = props;
  const { cdpId } = useParams();

  useEffect(() => {
    props.getCdpData(cdpId.replace(/[^\d]/g, ''), cdp.order);
  }, [cdpId]);

  const onOrderChange = (order) => {
    props.getCdpData(cdpId.replace(/[^\d]/g, ''), order);
  };

  // searchDataLoading check is needed so that component is re-added to DOM and animated
  if (searchDataLoading) return '';

  return (
    <div className="manage-page-wrapper dashboard-page-wrapper mcd">
      <div className="content-wrapper">
        <div className="width-container">
          {!cdp.cdpId && !searchDataObsolete && <NotFound type="cdp" />}
          {cdp.cdpId && (
            <>
              <MakerManageHeader />
              <div className="main-section-wrapper">
                <div className="main-subsections-wrapper">
                  <div className="main-subsection">
                    <MakerManageDataItems />
                    <MakerManageMcdInfoItems />
                  </div>

                  <MakerManageProfitCalc />
                </div>
              </div>

              <h1>History</h1>
              <CdpHistoryGraph txs={cdp.history} cdp={cdp} />
              <HistoryTable
                cdps={[cdp]}
                txs={cdp.history}
                history={props.history}
                singleCdp
                order={cdp.order}
                onOrderChange={onOrderChange}
              />

              <ManageCdpPanel cdpId={cdp.cdpId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MakerManage.propTypes = {
  getCdpData: PropTypes.func.isRequired,
  cdp: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  searchDataObsolete: PropTypes.bool.isRequired,
  searchDataLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
  searchDataObsolete: general.searchDataObsolete,
  searchDataLoading: general.searchDataLoading,
});

const mapDispatchToProps = {
  getCdpData,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(MakerManage), ErrorFallback);
