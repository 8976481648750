import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="footer-wrapper">
        <div className="width-container">
          <div className="company-info-wrapper">
            <div className="company-info">
              <div className="developed">Supported by<a target="_blank" rel="noopener noreferrer" href="https://defisaver.com/"><i className="defi-saver" /></a></div>
            </div>
          </div>

          <div className="social-media-links-wrapper">
            <div className="icons-wrapper">
              <a target="_blank" rel="noopener noreferrer" href="https://blog.defisaver.com">
                <i className="icon-defisaver" />
              </a>

              <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/XGDJHhZ">
                <i className="new-discord" />
              </a>

              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/defisaver">
                <i className="new-twitter" />
              </a>

              <a target="_blank" rel="noopener noreferrer" href="https://github.com/defisaver/defisaver-v3-contracts">
                <i className="new-github" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
