import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import IconExternal from '../../Decorative/IconExternal';
import Chevron from '../../Decorative/Chevron';

import './Header.scss';

/* eslint-disable jsx-a11y/control-has-associated-label */
const Header = () => {
  const [checked, setChecked] = useState(false);
  const absoluteHeader = window.location.pathname === '/';

  const changeChecked = () => { setChecked(!checked); };

  const id = 'menu-btn';

  return (
    <div className="header-wrapper">
      <div className="width-container flex padded">
        <Link to="" className="logo-wrapper">
          <span>DeFi Explore</span>
        </Link>

        <input className="menu-btn" onChange={changeChecked} type="checkbox" id={id} checked={checked} />
        <label className="menu-icon" htmlFor={id}><span className="navicon" /></label>

        <div className="links-wrapper">
          <NavLink className="link-lead" activeClassName="active" to="/" isActive={(match, location) => location.pathname.substr(0, 6) !== '/stats' && location.pathname.substr(0, 11) !== '/simulation' && location.pathname.substr(0, 14) !== '/trailing-stop' && location.pathname.substr(0, 12) !== '/liquidation'}>Explore</NavLink>
          <NavLink className="link-lead" activeClassName="active" to="/stats">Stats</NavLink>
          <NavLink className="link-lead" activeClassName="active" to="/simulation">Simulation</NavLink>
          <div className="dropdown">
            <a
              href=""
              onClick={(event) => event.preventDefault()}
              className="link-lead"
            >
              Other protocols
              <Chevron color="white" />
            </a>
            <div className="dropdown_content">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://liquity.defiexplore.com/"
                className="link-lead"
              >
                Liquity
                <IconExternal size={16} color="white" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chickenbonds.defiexplore.com/"
                className="link-lead"
              >
                Chicken Bonds
                <IconExternal size={16} color="white" />
              </a>
            </div>
          </div>
          {/* <NavLink activeClassName="active" to="/trailing-stop">Trailing Stop</NavLink> */}
          {/* <NavLink activeClassName="active" to="/liquidations" isActive={(match, location) => location.pathname.substr(0, 12) === '/liquidation'}>Liquidations</NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
