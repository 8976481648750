import React from 'react';
import ReactDOM from 'react-dom';
import ReactCountdown from 'react-countdown';
import propTypes from 'prop-types';

const renderer = ({
  hours, minutes, seconds, completed,
}) => {
  if (completed) return 'Done';
  if (hours > 1) return `${hours} hours`;
  if (hours) return '1 hour';
  if (minutes > 1) return `${minutes} minutes`;
  if (minutes) return '1 minute';
  if (seconds > 1) return `${seconds} seconds`;
  return '1 second';
};

const Countdown = ({ date }) => (
  <ReactCountdown date={date} renderer={renderer} />
);

Countdown.propTypes = {
  date: propTypes.object.isRequired,
};

export default Countdown;
