import React from 'react';
import PropTypes from 'prop-types';
import { usePagination, DOTS } from '../../hooks/usePagination';
import './Paginator.scss';


export default function Paginator({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  return (
    <div className="pagination">
      {currentPage === 1 ? (
        <button className="page disabled" disabled type="button">
          {'<'}
        </button>
      ) : (
        <button className="page" onClick={onPrevious} type="button">
          {'<'}
        </button>
      )}
      {paginationRange.map((number) => {
        if (number === DOTS) {
          return (
            <button
              className="page dots"
              key={number}
              disabled
              type="button"
            >
              {number}
            </button>
          );
        }
        return (
          <button
            className={`page ${
              number === currentPage && 'active'
            }`}
            key={number}
            disabled={number === currentPage}
            onClick={() => { onPageChange(number); }}
            type="button"
          >
            {number}
          </button>
        );
      })}
      {currentPage === Math.ceil(totalCount / pageSize) ? (
        <button className="page disabled" disabled type="button">
          {'>'}
        </button>
      ) : (
        <button className="page" onClick={onNext} type="button">
          {'>'}
        </button>
      )}
    </div>
  );
}

Paginator.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

Paginator.defaultProps = {
  siblingCount: 1,
};
