import React from 'react';
import t from 'translate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataItem from './DataItem/DataItem';
import { assetOrIlk, numberWithCommas } from '../../../services/utils';

import './MakerManageDataItems.scss';
import AutomationShield from '../../Decorative/AutomationShield/AutomationShield';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';

const MakerManageDataItems = ({ cdp }) => {
  const formatSubName = (sub) => {
    switch (sub) {
      case 'take-profit':
        return 'Take Profit';
      case 'stop-loss':
        return 'Stop Loss';
      case 'leverage-management':
      case 'legacy':
        return 'Leverage Management';
      default:
        return sub;
    }
  };
  return (
    <div className="maker-manage-data-items-wrapper">
      <DataItem
        label={t('common.collateral')}
        value={cdp.collateral}
        symbol={assetOrIlk(cdp.asset, cdp.ilk)}
        tooltip={`${numberWithCommas(cdp.collateral, 2)} ${
          cdp.asset
        }\n$${numberWithCommas(cdp.collateral * cdp.price, 2)}`}
        smallSymbol
      />

      <DataItem
        label={t('common.debt')}
        value={cdp.debt}
        symbol="DAI"
        smallSymbol
      />

      <DataItem
        label={`${t('common.ratio')} (${t('common.min_val', {
          '%value': `${Math.floor(cdp.liqRatio * 100)}%`,
        })})`}
        value={cdp.ratio * 100}
        symbol="%"
        showRatioCircle
        highlighted={cdp.subscribedToAutomation}
        active={cdp.subscribedToAutomation}
        valueAddon={
          cdp.subscribedToAutomation ? (
            <TooltipWrapper
              title={`Protected by DeFi Saver Automation\nSubscribed to:\n${cdp.subs.map(
                (sub) => `${formatSubName(sub)}\n`,
              )}`}
            >
              <AutomationShield active />
            </TooltipWrapper>
          ) : (
            ''
          )
        }
      />
    </div>
  );
};

MakerManageDataItems.defaultProps = {};

MakerManageDataItems.propTypes = {
  cdp: PropTypes.object.isRequired,
};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
});

export default connect(mapStateToProps)(MakerManageDataItems);
