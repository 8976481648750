import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import t from 'translate';
import {
  capitalize,
  nFormatter,
  parseTimestamp,
  shortAddress,
} from '../../services/utils';
import CdpStateItem from './CdpStateItem';

import './TxInfo.scss';
import Arrow from '../Decorative/Arrow';
import IconExternal from '../Decorative/IconExternal';
import ManageCdpPanel from '../ManageCdpPanel/ManageCdpPanel';
import NotFound from '../NotFound/NotFound';
import tenderlyLogo from './tenderly-logo.svg';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import { getTxData } from '../../actions/apiActions';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';
import AutomationShield from '../Decorative/AutomationShield/AutomationShield';

const TRANSLATED_FAIL_REASONS = [
  'vat/dust',
  'vat/not-safe',
  'vat/ceiling-exceeded',
  'dai/insufficient-allowance',
  'dai/insufficient-balance',
  'slippage hit',
  'out of gas',
  "final amount isn't correct",
];
const TRANSLATED_FAIL_SOLUTIONS = [
  'vat/dust',
  'vat/not-safe',
  'dai/insufficient-allowance',
  'dai/insufficient-balance',
  'slippage hit',
  'out of gas',
];

const TxInfo = (props) => {
  const { txData, searchDataObsolete, searchDataLoading } = props;
  const { txHash } = useParams();

  useEffect(() => {
    props.getTxData(txHash.replace(/[^0-9a-zA-Z]/g, ''));
  }, [txHash]);

  const doNotShow
  = !txData || !txData.txHash || txData.txStatus === 'not-found';

  if (!txData.txStatus) txData.txStatus = 'success';

  // searchDataLoading check is needed so that component is re-added to DOM and animated
  if ((doNotShow && searchDataObsolete) || searchDataLoading) return '';

  if (doNotShow && !searchDataObsolete) return <NotFound type="tx" />;

  if (txData.errorType !== 'execution reverted' && !txData.errorMessage) { txData.errorMessage = txData.errorType; }

  console.log(
    txData.errorCode,
    t(
      txData.errorCode
        || `tx_fail_reasons.${txData.errorMessage?.toLowerCase()}`,
    ),
  );

  return (
    <div className={`tx-info-wrapper ${txData.txStatus}`}>
      <div className="tx-info-header text-extra-large">
        <div>
          {txData.txStatus === 'success' && txData.cdpId && (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {t(`maker_actions.${txData.actionType}`)} (CDP{' '}
              <Link to={`/cdp/${txData.cdpId}`}>#{txData.cdpId}</Link>)
              {txData.automated && (
                <TooltipWrapper title="Automated action" useText>
                  <AutomationShield active />
                </TooltipWrapper>
              )}
            </span>
          )}
          {txData.txStatus === 'success' && !txData.cdpId && (
            <span>Transaction Successful</span>
          )}
          {txData.txStatus === 'failed' && (
            <span>Transaction Execution Reverted</span>
          )}
        </div>
        <div>
          <span>Tx Hash: </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://etherscan.io/tx/${txData.txHash}`}
          >
            {shortAddress(txData.txHash)}
            <IconExternal size={20} />
          </a>
        </div>
      </div>

      {(txData.txStatus === 'failed'
        || (txData.txStatus === 'success' && !txData.cdpId)) && (
        <div className="tx-failed-main-wrapper">
          <div className="top">
            {txData.txStatus === 'failed' && (
              <div>
                {!txData.errorMessage && (
                  <div className="meta-wrapper">
                    <div className="label">Reason</div>
                    <div className="value">
                      <i>No message provided</i>
                    </div>
                  </div>
                )}
                {txData.errorMessage && (
                  <div className="meta-wrapper">
                    <div className="label">Reason</div>
                    <div className="value">
                      <b>{capitalize(txData.errorMessage)}</b>
                    </div>
                    {TRANSLATED_FAIL_REASONS.includes(
                      txData.errorMessage?.toLowerCase(),
                    ) && (
                      <div className="value subtitle">
                        {t(
                          txData.errorCode
                            || `tx_fail_reasons.${txData.errorMessage?.toLowerCase()}`,
                        )}
                      </div>
                    )}
                  </div>
                )}
                {txData.errorMessage
                  && TRANSLATED_FAIL_SOLUTIONS.includes(
                    txData.errorMessage?.toLowerCase(),
                  ) && (
                    <div className="meta-wrapper">
                      <div className="label">Solution</div>
                      <div className="value subtitle">
                        {t(
                          `tx_fail_solutions.${txData.errorMessage?.toLowerCase()}`,
                        )}
                      </div>
                    </div>
                )}
              </div>
            )}
            <div>
              <div className="meta-wrapper">
                <div className="label">Date</div>
                <div className="value">{parseTimestamp(txData.timestamp)}</div>
              </div>

              <div className="meta-wrapper">
                <div className="label">Block Number</div>
                <div className="value">{txData.blockNumber}</div>
              </div>
            </div>
          </div>

          {txData.txData?.actionType && (
            <div className="debug-wrapper">
              <h3>Debug info:</h3>
              <div className="inner-wrapper">
                <div className="meta-wrapper">
                  <div className="label">Contract:</div>
                  <div className="value">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://etherscan.io/address/${txData.receiver}`}
                    >
                      {txData.receiver}
                      <IconExternal size={16} />
                    </a>
                  </div>
                </div>
                <div className="meta-wrapper">
                  <div className="label">Method:</div>
                  <div className="value">{txData.txData.actionType}</div>
                </div>
                <div className="meta-wrapper">
                  <div className="label">Params:</div>
                  <div className="value">
                    <pre>
                      <code>
                        {txData.txData.params.map((p) => (
                          <div className="code-row" key={p.name}>
                            <span>
                              {p.name}({p.type}):{' '}
                            </span>
                            <span>{p.value}</span>
                          </div>
                        ))}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
              <div className="debug-footer">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dashboard.tenderly.co/tx/main/${txData.txHash}?ref=defiexplore`}
                >
                  Open Debugger
                  <IconExternal size={12} color="white" />
                </a>
                <span>
                  Powered by
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://tenderly.co/?ref=defiexplore"
                  >
                    <img src={tenderlyLogo} alt="" />
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      {txData.txStatus === 'success' && txData.cdpId && (
        <div className="tx-info-main-wrapper">
          {txData.actionType === 'transfer' && (
            <div className="wide">
              <div>
                <h2>Before</h2>
                <div className="state-wrapper">
                  <div className="row before changed">
                    <div className="label">Owner</div>
                    <div className="value">
                      <a
                        href={`https://etherscan.io/address/${txData.before.owner}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {txData.before.owner}
                        <IconExternal size={16} color="white" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2>After</h2>
                <div className="state-wrapper">
                  <div className="row after changed">
                    <div className="label">Owner</div>
                    <div className="value">
                      <a
                        href={`https://etherscan.io/address/${txData.after.owner}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {txData.after.owner}
                        <IconExternal size={16} color="white" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {txData.actionType !== 'transfer' && (
            <>
              <div>
                <h2>Before</h2>
                <div className="state-wrapper">
                  <CdpStateItem
                    label="Debt"
                    valueSlug="debt"
                    suffix=" DAI"
                    after={false}
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Collateral"
                    valueSlug="collateral"
                    suffix={` ${txData.asset}`}
                    after={false}
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Liquidation Price"
                    valueSlug="liqPrice"
                    prefix="$"
                    after={false}
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Ratio"
                    valueSlug="ratio"
                    suffix="%"
                    after={false}
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                </div>
                <div className="arrow-desktop">
                  <Arrow color="white" orientation="right" />
                </div>
                <div className="arrow-mobile">
                  <Arrow color="white" orientation="bottom" />
                </div>
              </div>
              <div>
                <h2>After</h2>
                <div className="state-wrapper">
                  <CdpStateItem
                    label="Debt"
                    valueSlug="debt"
                    suffix=" DAI"
                    after
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Collateral"
                    valueSlug="collateral"
                    suffix={` ${txData.asset}`}
                    after
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Liquidation Price"
                    valueSlug="liqPrice"
                    prefix="$"
                    after
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                  <CdpStateItem
                    label="Ratio"
                    valueSlug="ratio"
                    suffix="%"
                    after
                    beforeCDP={txData.before}
                    afterCDP={txData.after}
                  />
                </div>
              </div>
            </>
          )}
          <div>
            <div className="meta-wrapper">
              {txData.price && (
                <>
                  <div className="label">Historic Price</div>
                  <div className="value">${nFormatter(txData.price)}</div>
                </>
              )}

              <div className="label">Date</div>
              <div className="value">{parseTimestamp(txData.timestamp)}</div>

              <div className="label">Block Number</div>
              <div className="value">{txData.blockNumber}</div>
            </div>
          </div>
        </div>
      )}

      {txData.liqId && (
        <div className="manage-cdp-panel-wrapper">
          <div>
            <h1>Auction {txData.liqActive ? 'active' : 'finished'}</h1>
            <p>
              {txData.liqActive
                ? 'Collateral auction is ongoing. You can visit the auction page to participate.'
                : 'Collateral auction is finished. You can visit the auction page to see bids and other data.'}
            </p>
          </div>
          <div>
            <Link
              to={`/liquidation/${txData.asset}/${txData.liqId}`}
              className="button"
            >
              View related auction
            </Link>
          </div>
        </div>
      )}

      {txData.cdpId && <ManageCdpPanel cdpId={txData.cdpId} />}
    </div>
  );
};

TxInfo.propTypes = {
  getTxData: PropTypes.func.isRequired,
  txData: PropTypes.object.isRequired,
  searchDataObsolete: PropTypes.bool.isRequired,
  searchDataLoading: PropTypes.bool.isRequired,
};

TxInfo.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  txData: general.txData,
  searchDataObsolete: general.searchDataObsolete,
  searchDataLoading: general.searchDataLoading,
});
const mapDispatchToProps = {
  getTxData,
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TxInfo),
  ErrorFallback,
);
