import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomePage from '../HomePage/HomePage';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Title from '../Title/Title';
import Stats from '../Stats/Stats';
import CdpSimulation from '../CdpSimulation/CdpSimulation';
import Liquidations from '../Liquidations/Liquidations';
import Liquidation from '../Liquidation/Liquidation';
import Search from '../Search/Search';
import TrailingStopSimulation from '../TrailingStopSimulation/TrailingStopSimulation';
import './App.scss';

import './share-thumbnail.jpg';
import './share-smaller.jpg';

const App = (props) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="app">
          <Title />
          <Header />

          <div className="page-content-wrapper">
            <Switch>
              <Route path="/cdp/:cdpId" component={HomePage} />
              <Route path="/tx/:txHash" component={HomePage} />
              <Route path="/address/:address" component={HomePage} />
              <Route path="/search" component={Search} />
              <Route path="/stats" component={Stats} />
              <Route path="/simulation" component={CdpSimulation} />
              <Route path="/trailing-stop" component={TrailingStopSimulation} />
              {/* <Route path="/liquidations" component={Liquidations} /> */}
              {/* <Route path="/liquidation/:cdpType/:liqId/:biteHash" component={Liquidation} /> */}
              <Route path="/" component={HomePage} />
            </Switch>
          </div>

          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(App);
