import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';

import './ErrorFallback.scss';

const ErrorFallback = ({ error, componentStack }) => {
  const [showDebug, toggleDebug] = useState(false);
  return (
    <div className="error-fallback-wrapper">
      <h2>An error occured while rendering the component.</h2>
      <a role="button" tabIndex={0} onClick={() => toggleDebug(!showDebug)}>Show debug information</a>
      {
        showDebug && (
          <pre>
              {error.message}
            {componentStack}
          </pre>
        )
      }
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.any.isRequired,
  componentStack: PropTypes.string.isRequired,
};

ErrorFallback.defaultProps = {};

export default ErrorFallback;
