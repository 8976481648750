import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { getAddressCsv } from '../../services/makerService';
import { parseUrl } from '../../actions/apiActions';
import ErrorFallback from '../ErrorFallback/ErrorFallback';

class AddressCsvExport extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { address } = parseUrl();
    const filename = `${address} history.csv`;
    const actions = this.props.addressData.history;
    return (
      <div className="csvexport-wrapper Flex FlexEnd MarginTop10">
        <a
          href={URL.createObjectURL(new Blob([getAddressCsv(actions)], { type: 'application/octet-stream' }))}
          download={filename}
        >Export CSV
        </a>
      </div>
    );
  }
}

AddressCsvExport.propTypes = {
  addressData: PropTypes.object.isRequired,
};

AddressCsvExport.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  addressData: general.addressData,
});

const mapDispatchToProps = {};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressCsvExport)), ErrorFallback);
