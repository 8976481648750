import React from 'react';
import PropTypes from 'prop-types';
import RCSlider from 'rc-slider';

import './Slider.scss';
import Caret from '../../Decorative/Caret';

const Slider = ({
  label, min, max, step, defaultValue, onChange, onAfterChange, value,
}) => {
  const ltzero = value < 0;
  const onChangeInput = (e) => {
    const { value } = e.target;
    if (value.length <= 1 && (value === '' || value === '-')) {
      onChange(value);
    } else {
      let parsedValue = parseFloat(value);
      if (!Number.isNaN(parsedValue)) {
        if (ltzero) {
          parsedValue *= -1;
          if (parsedValue < -100) {
            parsedValue = -100;
          }
        }
        onChange(parsedValue);
      }
    }
  };
  return (
    <div className="slider-input">
      <span className="slider-input__label">Avg. yearly growth</span>
      <div className="rc-slider__wrapper">
        <RCSlider
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          onAfterChange={onAfterChange}
        />
      </div>

      <div className={`slider-input__value ${ltzero ? 'under-zero' : ''}`}>
        <Caret size={10} color={ltzero ? '#EB5757' : '#37B06F'} orientation={ltzero ? 'down' : 'up'} />
        <input value={value < 0 ? value * -1 : value} onChange={onChangeInput} name="yearlyGrowth" />
        <label id="yearlyGrowth" className="second-label">%</label>
      </div>
    </div>
  );
};

Slider.defaultProps = {
  label: '',
  value: 1,
  min: 1,
  max: 2.8,
  step: 0.1,
  defaultValue: 1,
  onChange: () => {},
  onAfterChange: () => {},
};

Slider.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
};

export default Slider;
