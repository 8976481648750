import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { nFormatter } from '../../../../../services/utils';
import TooltipWrapper from '../../../../Decorative/TooltipWrapper/TooltipWrapper';

import './InfoItem.scss';

const InfoItem = ({
  label, description, value, symbol, loading, prefixSymbol, showChangeColor,
  additionalValue, additionalValueLabel, additionalValueTooltip, additionalValueIcon,
  additionalValuePrefixSymbol, additionalValueSymbol,
}) => (
  <div className="info-item-wrapper">
    <div className="info-item-value-wrapper">
      <span className="label">
        { description && (<TooltipWrapper title={description}><i className="icon icon-Info-circle" /></TooltipWrapper>) }
        { label }
      </span>

      <span className={`value ${showChangeColor && (value < 0 ? 'falling' : 'rising')}`}>
        <TooltipWrapper title={loading ? t('common.loading_no_dots') : value}>
          { loading && t('common.loading') }
          { !loading && (<>{value < 0 && '-'}{ prefixSymbol }{ nFormatter(Math.abs(value), 2) } { symbol }</>) }
        </TooltipWrapper>
      </span>

      {
        !!additionalValue && (
          <div className="additional-value">
            <span className="label">
              {additionalValueLabel}:{' '}
              <span className="value">
                <TooltipWrapper title={additionalValueTooltip || additionalValue} copy={!additionalValueTooltip}>
                  {additionalValueIcon} {additionalValue < 0 && '-'}{ additionalValuePrefixSymbol }{ nFormatter(Math.abs(additionalValue), 2) } { additionalValueSymbol }
                </TooltipWrapper>
              </span>
            </span>
          </div>
        )
      }

    </div>
  </div>
);

InfoItem.defaultProps = {
  description: '',
  loading: false,
  symbol: '',
  prefixSymbol: '',
  value: '',
  showChangeColor: false,
  additionalValue: '',
  additionalValueTooltip: '',
  additionalValueLabel: '',
  additionalValueIcon: '',
  additionalValueSymbol: '',
  additionalValuePrefixSymbol: '',
};

InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  symbol: PropTypes.string,
  prefixSymbol: PropTypes.string,
  additionalValueSymbol: PropTypes.string,
  additionalValuePrefixSymbol: PropTypes.string,
  showChangeColor: PropTypes.bool,
  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalValueTooltip: PropTypes.string,
  additionalValueLabel: PropTypes.string,
  additionalValueIcon: PropTypes.node,
};

export default InfoItem;
