const INITIAL_STATE = {
  globalInfo: {},
  globalInfoLoading: false,
  globalInfoError: '',

  actionsOverTimeLoading: false,
  actionsOverTimeError: '',
  actionsOverTime: {},

  ethPriceOverTimeLoading: false,
  ethPriceOverTimeError: '',
  ethPriceOverTime: [],

  cdpSimulationDataLoading: false,
  cdpSimulationDataError: '',
  cdpSimulationData: {
    'ETH-A': { exchPrices: [], makerPrices: [], rates: [] },
  },
  topCdps: {
    data: [],
    pageNumber: 1,
    total: 0,
    order: 'DESC',
    sortBy: 'debt',
    filters: [],
  },
  topCdpsLoading: false,
  topCdpsError: '',

  searchQuery: '',
  searchDataLoading: false,
  searchDataError: '',
  searchDataObsolete: false,
  searchDataFetched: false,

  cdpData: { order: 'DESC' },
  addressData: { orderTx: 'DESC', order: 'DESC', sortBy: 'debt' },
  txData: {},

  liquidations: [],
  liquidationsLoading: false,
  liquidationsPage: 1,
  liquidationsFilter: 'any',

  liquidationData: null,
  liquidationDataLoading: false,
  liquidationDataError: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GLOBAL_INFO_REQUEST':
      return { ...state, globalInfoLoading: true, globalInfoError: '' };

    case 'GLOBAL_INFO_SUCCESS':
      return {
        ...state,
        globalInfoLoading: false,
        globalInfoError: '',
        globalInfo: { ...payload },
      };

    case 'GLOBAL_INFO_ERROR':
      return { ...state, globalInfoLoading: false, globalInfoError: payload };

    case 'ACTIONS_OVER_TIME_REQUEST':
      return { ...state, actionsOverTimeLoading: true, actionsOverTimeError: '' };

    case 'ACTIONS_OVER_TIME_SUCCESS':
      return {
        ...state,
        actionsOverTimeLoading: false,
        actionsOverTimeError: '',
        actionsOverTime: { ...payload },
      };

    case 'ACTIONS_OVER_TIME_ERROR':
      return { ...state, actionsOverTimeLoading: false, actionsOverTimeError: payload };

    case 'ETH_PRICE_OVER_TIME_REQUEST':
      return { ...state, ethPriceOverTimeLoading: true, ethPriceOverTimeError: '' };

    case 'ETH_PRICE_OVER_TIME_SUCCESS':
      return {
        ...state,
        ethPriceOverTimeLoading: false,
        ethPriceOverTimeError: '',
        ethPriceOverTime: [...payload],
      };

    case 'ETH_PRICE_OVER_TIME_ERROR':
      return { ...state, ethPriceOverTimeLoading: false, ethPriceOverTimeError: payload };

    case 'CDP_SIMULATION_DATA_REQUEST':
      return { ...state, cdpSimulationDataLoading: true, cdpSimulationDataError: '' };

    case 'CDP_SIMULATION_DATA_SUCCESS':
      return {
        ...state,
        cdpSimulationDataLoading: false,
        cdpSimulationDataError: '',
        cdpSimulationData: {
          ...state.cdpSimulationData,
          [payload.ilk]: payload,
        },
      };

    case 'CDP_SIMULATION_DATA_ERROR':
      return { ...state, cdpSimulationDataLoading: false, cdpSimulationDataError: payload };

    case 'TOP_CDPS_REQUEST':
      return { ...state, topCdpsLoading: true, topCdpsError: '' };

    case 'TOP_CDPS_SUCCESS':
      return {
        ...state,
        topCdpsLoading: false,
        topCdpsError: '',
        topCdps: payload,
      };

    case 'TOP_CDPS_ERROR':
      return { ...state, topCdpsLoading: false, topCdpsError: payload };

    case 'CDP_DATA_REQUEST':
    case 'ADDRESS_DATA_REQUEST':
    case 'TX_DATA_REQUEST':
      return {
        ...state,
        searchDataLoading: true,
        searchDataError: '',
        searchDataFetched: false,
      };

    case 'CDP_DATA_SUCCESS':
      return {
        ...state,
        cdpData: { ...payload },
        searchDataError: '',
        searchDataLoading: false,
        searchDataObsolete: false,
        searchDataFetched: true,
      };

    case 'ADDRESS_DATA_SUCCESS':
      return {
        ...state,
        addressData: { ...payload },
        searchDataError: '',
        searchDataLoading: false,
        searchDataObsolete: false,
        searchDataFetched: true,
      };

    case 'TX_DATA_SUCCESS':
      return {
        ...state,
        txData: { ...payload },
        searchDataError: '',
        searchDataLoading: false,
        searchDataObsolete: false,
        searchDataFetched: true,
      };

    case 'SEARCH_DATA_QUERY':
      return {
        ...state,
        searchDataObsolete: state.searchQuery !== payload,
        searchQuery: payload,
      };

    case 'CDP_DATA_ERROR':
    case 'ADDRESS_DATA_ERROR':
    case 'TX_DATA_ERROR':
      return {
        ...state,
        cdpData: { order: 'DESC' },
        addressData: { orderTx: 'DESC', order: 'DESC', sortBy: 'debt' },
        txData: {},
        searchDataLoading: false,
        searchDataError: payload,
      };

    case 'SEARCH_DATA_OBSOLETE':
      return { ...state, searchDataObsolete: true };

    case 'SEARCH_DATA_CLEAR':
      return {
        ...state,
        cdpData: { order: 'DESC' },
        addressData: { orderTx: 'DESC', order: 'DESC', sortBy: 'debt' },
        txData: {},
        searchQuery: '',
        searchDataLoading: false,
        searchDataError: '',
        searchDataObsolete: false,
        searchDataFetched: false,
      };

    case 'LIQUIDATIONS_REQUEST':
      return {
        ...state,
        liquidations: [],
        liquidationsLoading: true,
      };

    case 'LIQUIDATIONS_SUCCESS':
      return {
        ...state,
        liquidations: action.payload,
        liquidationsLoading: false,
      };

    case 'LIQUIDATIONS_CHANGE_QUERY':
      return {
        ...state,
        liquidationsPage: action.payload.page,
        liquidationsFilter: action.payload.filter,
      };

    case 'LIQUIDATION_REQUEST':
      return {
        ...state,
        liquidationData: null,
        liquidationDataLoading: true,
        liquidationDataError: '',
      };

    case 'LIQUIDATION_SUCCESS':
      return {
        ...state,
        liquidationData: action.payload,
        liquidationDataLoading: false,
      };

    case 'LIQUIDATION_ERROR':
      return {
        ...state,
        liquidationData: null,
        liquidationDataLoading: false,
        liquidationDataError: action.payload,
      };

    default:
      return state;
  }
};
