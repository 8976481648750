import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RatioCircle from './RatioCircle';
import { getColorForRatio } from '../../../services/utils';

const CdpRatioCircle = ({ cdp }) => (
  <RatioCircle color={getColorForRatio(cdp.ratio * 100, cdp.liqRatio * 100)} />
);

CdpRatioCircle.defaultProps = {
};

CdpRatioCircle.propTypes = {
  cdp: PropTypes.object.isRequired,
};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
});

export default connect(mapStateToProps, {})(CdpRatioCircle);
