import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import { getCdpCsv } from '../../services/makerService';
import ErrorFallback from '../ErrorFallback/ErrorFallback';

class CdpCsvExport extends Component {
  constructor(props) {
    super();
  }

  render() {
    const filename = `#${this.props.cdpData?.cdpId} history.csv`;
    const actions = this.props.cdpData.history;
    return (
      <div className="csvexport-wrapper Flex FlexEnd MarginTop10">
        <a
          href={URL.createObjectURL(new Blob([getCdpCsv(actions)], { type: 'application/octet-stream' }))}
          download={filename}
        >Export CSV
        </a>
      </div>
    );
  }
}

CdpCsvExport.propTypes = {
  cdpData: PropTypes.object.isRequired,
};

CdpCsvExport.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  cdpData: general.cdpData,
});

const mapDispatchToProps = {};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(CdpCsvExport), ErrorFallback);
