import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import { getLiqudationsData } from '../../actions/apiActions';
import { nFormatter, parseTimestamp } from '../../services/utils';
import Arrow from '../Decorative/Arrow';

import './Liquidations.scss';

class Liquidations extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getLiqudationsData();
  }

  render() {
    const {
      liquidations, liquidationsLoading, liquidationsPage, liquidationsFilter, history,
    } = this.props;

    return (
      <div className="liquidations-wrapper">
        <div className="width-container padded">
          <div className="flex title-wrapper">
            <h1>Liquidations</h1>
            <div className="Switch">
              <button
                type="button"
                className={`${liquidationsFilter === 'any' ? 'active' : ''}`}
                onClick={() => this.props.getLiqudationsData('any', 1)}
              >
                All
              </button>
              <button
                type="button"
                className={`${liquidationsFilter === 'active' ? 'active' : ''}`}
                onClick={() => this.props.getLiqudationsData('active', 1)}
              >
                Active
              </button>
              <button
                type="button"
                className={`${
                  liquidationsFilter === 'finished' ? 'active' : ''
                }`}
                onClick={() => this.props.getLiqudationsData('finished', 1)}
              >
                Finished
              </button>
            </div>
          </div>
        </div>

        <div className="width-container">
          {liquidations.length > 0 && (
            <div className="styled-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>CDP ID</th>
                    <th
                      onClick={() => getLiqudationsData(liquidationsFilter, 1)}
                    >
                      Liq. ID
                    </th>
                    <th>Phase</th>
                    <th
                      onClick={() => getLiqudationsData(liquidationsFilter, 1)}
                    >
                      Phase 1 (DAI Auction)
                    </th>
                    <th>Phase 2 (Coll Auction)</th>
                    <th>Started</th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {liquidations.map((liq) => (
                    <tr
                      key={`${liq.liqId}-${liq.cdpType}`}
                      onClick={() => history.push(
                        `/liquidation/${liq.cdpType}/${liq.liqId}/${liq.biteHash}`,
                      )}
                    >
                      <td>{liq.cdpId}</td>
                      <td>{liq.liqId}</td>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      <td>
                        {liq.finished
                          ? 'Done'
                          : liq.dent
                            ? 'Phase 2'
                            : 'Phase 1'}
                      </td>
                      <td>
                        {nFormatter(liq.tend)}/{nFormatter(liq.debt)} DAI (
                        {nFormatter(100 * (liq.tend / liq.debt))}%)
                      </td>
                      <td>
                        {nFormatter(liq.dent)}/{nFormatter(liq.collateral)}{' '}
                        {liq.cdpType} (
                        {nFormatter(100 * (liq.dent / liq.collateral))}%)
                      </td>
                      <td>{parseTimestamp(liq.timestamp)} UTC</td>
                      <td>
                        <Link
                          to={`/liquidation/${liq.cdpType}/${liq.liqId}/${liq.biteHash}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Arrow orientation="right" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {liquidations.length === 0 && !liquidationsLoading && (
            <p className="Flex white-text">
              No {liquidationsFilter !== 'any' && liquidationsFilter} auctions
            </p>
          )}

          {liquidations.length === 0 && liquidationsLoading && (
            <p className="Flex white-text">Loading...</p>
          )}

          <br />

          <div className="Flex SpaceBetween MarginTop10">
            <button
              type="button"
              className="button"
              onClick={() => this.props.getLiqudationsData(
                liquidationsFilter,
                liquidationsPage - 1,
              )}
              disabled={liquidationsPage < 2}
            >
              Prev Page
            </button>
            <span>Page {liquidationsPage}</span>
            <button
              type="button"
              className="button"
              onClick={() => this.props.getLiqudationsData(
                liquidationsFilter,
                liquidationsPage + 1,
              )}
              disabled={liquidations.length !== 30}
            >
              Next Page
            </button>
          </div>
          <br />
        </div>
      </div>
    );
  }
}

Liquidations.propTypes = {
  getLiqudationsData: PropTypes.func.isRequired,
  liquidations: PropTypes.array.isRequired,
  liquidationsLoading: PropTypes.bool.isRequired,
  liquidationsPage: PropTypes.number.isRequired,
  liquidationsFilter: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

Liquidations.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  liquidations: general.liquidations,
  liquidationsLoading: general.liquidationsLoading,
  liquidationsPage: general.liquidationsPage,
  liquidationsFilter: general.liquidationsFilter,
});

const mapDispatchToProps = {
  getLiqudationsData,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(withRouter(Liquidations)), ErrorFallback);
