/* eslint-disable */
import React from 'react';

const IconExternal = ({ size = 14, color = '#5BB4A7' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      verticalAlign: 'middle',
      marginLeft: `${Math.floor(size / 4)}px`,
    }}
  >
    <path
      d="M6.5 3.5H1V13.5H11V8M8.5 1H13.5M13.5 1V6M13.5 1L6.5 8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

);
export default IconExternal;
