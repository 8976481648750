import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { getAssetInfo } from '@defisaver/tokens';
import { getColorForRatio, nFormatter } from '../../services/utils';
import HistoryTable from '../HistoryTable/HistoryTable';
import Arrow from '../Decorative/Arrow';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import './AddressInfo.scss';
import AutomationShield from '../Decorative/AutomationShield/AutomationShield';
import { getAddressData } from '../../actions/apiActions';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';
import Chevron from '../Decorative/Chevron';

const AddressInfo = (props) => {
  const { addressData, searchDataLoading } = props;
  const { address } = useParams();

  useEffect(() => {
    props.getAddressData(address.replace(/[^\d\w.]/g, ''), addressData.orderTx, addressData.order, addressData.sortBy);
  }, [address]);

  const onTxOrderChange = (order) => {
    props.getAddressData(address.replace(/[^\d\w.]/g, ''), order, addressData.order, addressData.sortBy);
  };

  const onOrderChange = (order, sortBy) => {
    props.getAddressData(address.replace(/[^\d\w.]/g, ''), addressData.order, order, sortBy);
  };

  const toggleSorting = (column) => {
    const order = addressData.sortBy === column ? addressData.order === 'DESC' ? 'ASC' : 'DESC' : 'DESC';
    onOrderChange(order, column);
  };

  // searchDataLoading check is needed so that component is re-added to DOM and animated
  if (!addressData.cdps || !addressData.history || searchDataLoading) return '';

  return (
    <div className="address-info-wrapper">
      <h1>Vaults</h1>
      {
        addressData.cdps.length === 0 && (<p>No Vaults found.</p>)
      }
      {
        addressData.cdps.length > 0 && (
          <div className="styled-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th
                    className={`th_with_arrow ${
                      addressData.sortBy === 'debt' ? 'active' : ''
                    }`}
                    onClick={() => {
                      toggleSorting('debt');
                    }}
                  >
                    <div>
                      Debt
                      {addressData.sortBy === 'debt' ? (
                        <Chevron
                          orientation={addressData.order === 'DESC' ? 'down' : 'up'}
                          color="white"
                          size={25}
                        />
                      ) : (
                        <div className="multiple-arrows">
                          <Chevron
                            orientation="up"
                            size={25}
                          />
                          <Chevron
                            orientation="down"
                            size={25}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                  <th
                    className={`th_with_arrow ${
                      addressData.sortBy === 'collateral' ? 'active' : ''
                    }`}
                    onClick={() => {
                      toggleSorting('collateral');
                    }}
                  >
                    <div>
                      Collateral
                      {addressData.sortBy === 'collateral' ? (
                        <Chevron
                          orientation={addressData.order === 'DESC' ? 'down' : 'up'}
                          color="white"
                          size={25}
                        />
                      ) : (
                        <div className="multiple-arrows">
                          <Chevron
                            orientation="up"
                            size={25}
                          />
                          <Chevron
                            orientation="down"
                            size={25}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                  <th>Liquidation price</th>
                  <th
                    className={`th_with_arrow ${
                      addressData.sortBy === 'ratio' ? 'active' : ''
                    }`}
                    onClick={() => {
                      toggleSorting('ratio');
                    }}
                  >
                    <div>
                      Ratio
                      {addressData.sortBy === 'ratio' ? (
                        <Chevron
                          orientation={addressData.order === 'DESC' ? 'down' : 'up'}
                          color="white"
                          size={25}
                        />
                      ) : (
                        <div className="multiple-arrows">
                          <Chevron
                            orientation="up"
                            size={25}
                          />
                          <Chevron
                            orientation="down"
                            size={25}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  addressData.cdps?.map?.((cdp) => (
                    <tr key={cdp.cdpId} onClick={() => props.history.push(`/cdp/${cdp.cdpId}`)}>
                      <td>{getAssetInfo(cdp.asset)?.icon()} {cdp.cdpId}</td>
                      <td><TooltipWrapper title={cdp.debt}>{nFormatter(cdp.debt)} DAI</TooltipWrapper></td>
                      <td><TooltipWrapper title={cdp.collateral}>{nFormatter(cdp.collateral)} {cdp.asset}</TooltipWrapper></td>
                      <td><TooltipWrapper title={cdp.liqPrice}>${nFormatter(cdp.liqPrice)}</TooltipWrapper></td>
                      <td style={{ color: getColorForRatio(cdp.ratio * 100, cdp.liqRatio * 100) }}>
                        {cdp.subscribedToAutomation ? <TooltipWrapper title="Protected by DeFi Saver Automation"><AutomationShield active /></TooltipWrapper> : ''}
                        {nFormatter(cdp.ratio * 100)}%
                      </td>
                      <td><Arrow orientation="right" /></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
      <h1>History</h1>
      {
        addressData.history.length === 0 && (<p>No activities found.</p>)
      }
      {
        addressData.history.length > 0
        && (
        <HistoryTable
          txs={addressData.history}
          history={props.history}
          cdps={addressData.cdps}
          order={addressData.orderTx}
          onOrderChange={onTxOrderChange}
        />
        )
      }
    </div>
  );
};

AddressInfo.propTypes = {
  getAddressData: PropTypes.func.isRequired,
  addressData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  searchDataLoading: PropTypes.bool.isRequired,
};

AddressInfo.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  addressData: general.addressData,
  searchDataLoading: general.searchDataLoading,
});

const mapDispatchToProps = {
  getAddressData,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(AddressInfo), ErrorFallback);
