/* eslint-disable */
import React from 'react';

const Arrow = ({ size = 20, color = '#61717E', orientation = 'down' }) => (
  <svg
    className="decorative-arrow"
    style={{ transform: `rotate(${{ down: 0, left: 90, up: 180, right: 270 }[orientation]}deg)` }}
    width={size} height={size} viewBox={`0 0 20 21`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 14L10 20L4 14" stroke={color} />
    <line x1="10" y1="-2.18557e-08" x2="10" y2="20" stroke={color} />
  </svg>
);
export default Arrow;
