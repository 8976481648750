import Dec from 'decimal.js';
import { blockToDate, nFormatter } from './utils';
import graphPointBoost from '../components/CdpSimulation/graph-action-point-boost.svg';
import graphPointRepay from '../components/CdpSimulation/graph-action-point-repay.svg';
import {
  addArrays,
  cumsum, exp, generateRandomNormalDistribution, linspace, mulArrBy,
} from './mathUtils';

const config = {
  contract: {
    maxGasPrice: 490e9,
    boostGasCost: 1000000,
    repayGasCost: 1000000,
    closeGasCost: 1000000,
  },
  global: {
    feeDivider: 333, // 0.3%
  },
};
export const ilkLiquidationRatios = {
  'ETH-A': 1.45,
  'ETH-B': 1.3,
  'ETH-C': 1.7,
  'WBTC-A': 1.45,
  'WBTC-B': 1.3,
  'WBTC-C': 1.75,
  'YFI-A': 1.65,
  'LINK-A': 1.65,
  'UNI-A': 1.75,
  'RENBTC-A': 1.65,
  'MANA-A': 1.75,
  // 'WSTETH-A': 1.6, missing otc prices
  'MATIC-A': 1.75,
};
/**
 * Amount calculation taken from
 * https://github.com/DecenterApps/defisaver-automation-v2/
 */

/**
 * @param _gasPrice
 * @param ethPrice
 * @param type {string}
 * @returns {Decimal} Gas fee in DAI
 */
const getTxFee = (_gasPrice, ethPrice, type) => {
  let gasPrice = new Dec(_gasPrice);
  if (gasPrice.gt(new Dec(config.contract.maxGasPrice))) {
    gasPrice = new Dec(config.contract.maxGasPrice);
  }

  const gasAmount = type === 'boost'
    ? config.contract.boostGasCost
    : (type === 'repay' ? config.contract.repayGasCost : config.contract.closeGasCost);

  const gasFee = gasPrice.mul(gasAmount);

  const gasFeeEth = gasFee.div(1e18);
  return gasFeeEth.mul(ethPrice);
};

const getRatio = (_daiDebt, _collateral, _price) => {
  const daiDebt = Dec(_daiDebt);
  const collateral = Dec(_collateral);
  const price = Dec(_price);

  if (daiDebt.eq(0)) return Dec(Infinity);

  return collateral.mul(price).div(daiDebt);
};

const getFees = (_daiAmount, _gasPrice, _ethPrice, type) => {
  const daiAmount = Dec(_daiAmount);
  const gasPrice = Dec(_gasPrice);
  const price = Dec(_ethPrice);

  if (type !== 'repay' && type !== 'boost' && type !== 'close') throw new Error('Calc fee, type is not valid');
  if (price.eq(0)) throw new Error('getFees - price is zero');

  const gasFeeDai = getTxFee(gasPrice, _ethPrice, type);

  const serviceFeeUsd = new Dec(daiAmount).mul(0.3 / 100);

  return {
    gasFeeUsd: gasFeeDai.toString(),
    serviceFeeUsd: serviceFeeUsd.toString(),
  };
};

export const valuesAfterRepay = (_daiDebt, _collateral, _ethDrawn, _price, _exchangePrice, _gasPrice, ethPrice) => {
  const daiDebt = Dec(_daiDebt);
  const collateral = Dec(_collateral);
  const price = Dec(_price);
  const ethDrawn = Dec(_ethDrawn);
  const exchangePrice = Dec(_exchangePrice);
  const gasPrice = Dec(_gasPrice);

  // if (daiDebt.eq(0)) throw new Error('ratioAfterRepay - daiDebt is zero');

  let daiReturned = ethDrawn.mul(exchangePrice);

  const { gasFeeUsd, serviceFeeUsd } = getFees(daiReturned, gasPrice, ethPrice, 'repay');
  daiReturned = daiReturned.minus(gasFeeUsd).minus(serviceFeeUsd);

  return {
    ratio: getRatio(daiDebt.minus(daiReturned), collateral.minus(ethDrawn), price),
    debt: daiDebt.minus(daiReturned),
    col: collateral.minus(ethDrawn),
    collChange: ethDrawn.toString(),
    debtChange: daiReturned.toString(),
    gasFeeUsd,
    serviceFeeUsd,
  };
};

export const valuesAfterBoost = (_daiDebt, _collateral, _daiDrawn, _price, _exchangePrice, _gasPrice, ethPrice) => {
  const daiDebt = Dec(_daiDebt);
  const collateral = Dec(_collateral);
  const price = Dec(_price);
  const daiDrawn = Dec(_daiDrawn);
  const exchangePrice = Dec(_exchangePrice);
  const gasPrice = Dec(_gasPrice);

  // if (daiDebt.eq(0)) throw new Error('ratioAfterBoost - daiDebt is zero');

  const { gasFeeUsd, serviceFeeUsd } = getFees(daiDrawn, gasPrice, ethPrice, 'boost');
  const ethReturned = daiDrawn.minus(gasFeeUsd).minus(serviceFeeUsd).div(exchangePrice);

  return {
    debt: daiDebt.plus(daiDrawn),
    col: collateral.plus(ethReturned),
    ratio: getRatio(daiDebt.plus(daiDrawn), collateral.plus(ethReturned), price),
    collChange: ethReturned.toString(),
    debtChange: daiDrawn.toString(),
    gasFeeUsd,
    serviceFeeUsd,
  };
};

const getMaxCollateral = (daiDebt, price) => daiDebt.div(price);

const getMaxDebt = (
  daiDebt, collateral, price, liquidationRatio, exchangePrice,
) => collateral.times(price).sub(liquidationRatio.mul(daiDebt))
  .div(liquidationRatio.sub(price.mul(new Dec(1).div(exchangePrice))))
  .mul(0.995);

/**
 * Calculates boost amount
 *
 * @param vault {object}
 * @param _mkrPrice
 * @param _exchangePrice
 * @param _gasPrice {Number} gasPrice in wei
 * @param ethPrice {Number} eth price in decimal (not in wei)
 */
const getBoostAmount = (vault, _mkrPrice, _exchangePrice, _gasPrice, ethPrice) => {
  const price = new Dec(_mkrPrice);
  const exchangePrice = new Dec(_exchangePrice);
  const gasPrice = new Dec(_gasPrice);
  const daiDebt = new Dec(vault.debt);
  const collateral = new Dec(vault.collateral);
  const optimalRatio = new Dec(vault.optimalRatioBoost);
  const gasCostInDai = getTxFee(gasPrice, ethPrice, 'boost');
  const feeDivider = new Dec(config.global.feeDivider);

  // if (new Dec(vault.debt).eq(0)) throw new Error('getBoostAmount - vaults debt is zero');

  const maxDebt = getMaxDebt(
    daiDebt,
    collateral,
    price,
    new Dec(vault.liqRatio),
    exchangePrice,
  );

  // this is formula when not hitting the max fee, so we first calculate like this
  const debtNeeded = feeDivider
    .mul(new Dec(-1).mul(collateral).mul(price).mul(exchangePrice)
      .plus(daiDebt.mul(exchangePrice).mul(optimalRatio))
      .plus(price.mul(gasCostInDai)))
    .div(
      price.mul(feeDivider.minus(1))
        .minus(exchangePrice.mul(optimalRatio).mul(feeDivider)),
    );

  // check if the fee is too big (gasCostInDai bigger than 20% of amount)
  // based on calculated collateral
  if (gasCostInDai.gt(debtNeeded.div(5))) {
    const debtForMaxFee = new Dec(5)
      .mul(daiDebt.mul(exchangePrice).mul(optimalRatio)
        .minus(collateral.mul(price).mul(exchangePrice)))
      .div(new Dec(4).mul(price)
        .minus(new Dec(5).mul(exchangePrice).mul(optimalRatio)));

    return debtForMaxFee.gt(maxDebt) ? maxDebt.toFixed(8) : debtForMaxFee.toFixed(8);
  }

  return debtNeeded.gt(maxDebt) ? maxDebt.toFixed(8) : debtNeeded.toFixed(8);
};

/**
 * Calculates repay amount
 *
 * @param vault {object}
 * @param _mkrPrice
 * @param _exchangePrice
 * @param _gasPrice {Number} gasPrice in wei
 * @param ethPrice {Number} eth price in decimal (not in wei)
 */
const getRepayAmount = (vault, _mkrPrice, _exchangePrice, _gasPrice, ethPrice) => {
  const price = new Dec(_mkrPrice);
  const exchangePrice = new Dec(_exchangePrice);
  const gasPrice = new Dec(_gasPrice);
  const daiDebt = new Dec(vault.debt);
  const collateral = new Dec(vault.collateral);
  const optimalRatio = new Dec(vault.optimalRatioRepay);
  const gasCostInDai = getTxFee(gasPrice, ethPrice, 'repay');
  const feeDivider = new Dec(config.global.feeDivider);

  if (new Dec(vault.debt).eq(0)) throw new Error('getRepayAmount - vaults debt is zero');

  // get maximum possible collateral to withdraw
  const maxCollateral = getMaxCollateral(daiDebt, exchangePrice);
  // this is formula when not hitting the max fee, so we first calculate like this
  const collNeeded = feeDivider
    .mul(
      collateral.mul(price).minus(optimalRatio.mul(daiDebt)).minus(optimalRatio.mul(gasCostInDai)),
    )
    .div(
      price
        .mul(feeDivider)
        .minus(exchangePrice.mul(optimalRatio).mul(feeDivider))
        .plus(exchangePrice.mul(optimalRatio)),
    );

  // check if the fee is too big (gasCostInDai bigger than 20% of amount)
  // based on calculated collateral
  if (gasCostInDai.gt(collNeeded.mul(exchangePrice).div(5))) {
    const collForMaxFee = new Dec(5).mul(collateral.mul(price).minus(daiDebt.mul(optimalRatio)))
      .div(new Dec(5).mul(price).minus(new Dec(4).mul(exchangePrice).mul(optimalRatio)));

    return collForMaxFee.gt(maxCollateral) ? maxCollateral.toFixed(8) : collForMaxFee.toFixed(8);
  }

  return collNeeded.gt(maxCollateral) ? maxCollateral.toFixed(8) : collNeeded.toFixed(8);
};
const getAmount = (type, currCdp, currPrice, exchangePrice, gasPrice, ethPrice) => {
  if (type === 'Boost') return getBoostAmount(currCdp, currPrice, exchangePrice, gasPrice, ethPrice);
  return getRepayAmount(currCdp, currPrice, exchangePrice, gasPrice, ethPrice);
};

const getValues = (type, currCdp, amount, currPrice, exchangePrice, gasPrice, ethPrice) => {
  if (type === 'Boost') return valuesAfterBoost(currCdp.debt, currCdp.collateral, amount, currPrice, exchangePrice, gasPrice, ethPrice);
  return valuesAfterRepay(currCdp.debt, currCdp.collateral, amount, currPrice, exchangePrice, gasPrice, ethPrice);
};

const findLastIndex = (arr, check, _i = 0) => {
  let alreadyFound = false;
  for (let i = _i; i < arr.length; i++) {
    const is = check(arr[i]);
    if (alreadyFound && !is) return i - 1;
    alreadyFound = is;
  }
  if (alreadyFound) return arr.length - 1;
  return -1;
};

export const calculateTrailingStop = (cdp, mPrices, _ePrices) => {
  const currCdp = { ...cdp };
  const ePrices = [..._ePrices];

  let optimum = 0;
  let currentStop = 0;
  let stopTimestamp = 0;

  try {
    let startAmountUsd = 0;
    let currPrice = 0;
    let exchangePrice = 0;
    let exchangePriceI = 0;
    let stopTriggered = false;
    let stopPrice = 0;

    const stops = [];

    for (let i = 0; i < mPrices.length; i++) {
      const block = mPrices[i][0];
      if (block < currCdp.block) continue;


      exchangePriceI = findLastIndex(ePrices, (d) => d[0] <= block, exchangePriceI);
      if (exchangePriceI === -1) debugger; // eslint-disable-line
      exchangePrice = ePrices[exchangePriceI][1];
      if (startAmountUsd === 0) startAmountUsd = exchangePrice * currCdp.collateral;

      currPrice = Dec(mPrices[i][1]);

      if (!stopTriggered && parseInt(currCdp.trailingStop, 10) !== 0) {
        if (optimum === 0) {
          optimum = exchangePrice;
        }
        if (currCdp.type === 'LONG') {
          currentStop = optimum * ((100 - parseInt(currCdp.trailingStop, 10)) / 100);
          if (optimum < exchangePrice) optimum = exchangePrice;
          stops.push([block, currentStop]);
          if (exchangePrice <= currentStop) {
            stopTriggered = true;
            stopTimestamp = block;
            stopPrice = currPrice;
          }
        } else {
          currentStop = optimum * ((100 + parseInt(currCdp.trailingStop, 10)) / 100);
          if (optimum > exchangePrice) optimum = exchangePrice;
          stops.push([block, currentStop]);
          if (exchangePrice >= currentStop) {
            stopTriggered = true;
            stopTimestamp = block;
            stopPrice = currPrice;
          }
        }
      }
    }

    const finalCdp = {
      ...cdp,
      price: stopTriggered ? stopPrice : currPrice.toPrecision(5),
      collateral: currCdp.collateral,
      profit: Dec(currCdp.collateral).mul(stopTriggered ? stopPrice : currPrice.toPrecision(5)).minus(startAmountUsd)
        .toString(),
      optimum,
      stopTimestamp,
      profitWithoutStop: Dec(currCdp.collateral).mul(currPrice.toPrecision(5)).minus(startAmountUsd)
        .toString(),
      currentStop,
    };
    return {
      stops,
      finalCdp,
    };
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const calculate = (cdp, mPrices, _ePrices, _rates, ethPrices) => {
  const currCdp = { ...cdp };
  currCdp.maxBoost /= 100;
  currCdp.minRepay /= 100;
  currCdp.optimalRatioBoost /= 100;
  currCdp.optimalRatioRepay /= 100;
  const rates = [..._rates];
  const ePrices = [..._ePrices];
  const initialRate = rates[findLastIndex(rates, (d) => d[0] <= cdp.block)][1];
  const initialPrice = ePrices.find((d) => d[0] <= cdp.block)[1];
  currCdp.normalizedDebt = Dec(cdp.debt).times(1e27).div(initialRate);

  try {
    const gasPrice = cdp.gasPrice * 1e9;
    let liquidationPrice = 0;
    let startAmountUsd = 0;
    let startAmount = 0;
    let currPrice = 0;
    let exchangePrice = 0;
    let liquidated = false;
    let exchangePriceI = 0;
    let ethPriceI = 0; // used for gas fee estimate in case of other assets
    let rateI = 0;
    let optimum = 0;
    let currentStop = 0;
    let stopTimestamp = 0;
    let stopTriggered = false;
    let stopPrice = 0;
    let stopLiqRatio = 0;

    const stops = [];
    const actions = [];
    const liquidationInfo = {
      price: 0,
      block: 0,
    };

    for (let i = 0; i < mPrices.length; i++) {
      const block = mPrices[i][0];
      if (block < currCdp.block) continue;
      // if (toBlock > 0 && block > toBlock) break;

      exchangePriceI = findLastIndex(ePrices, (d) => d[0] <= block, exchangePriceI);
      if (exchangePriceI === -1) debugger; // eslint-disable-line
      exchangePrice = ePrices[exchangePriceI][1];

      ethPriceI = findLastIndex(ethPrices, (d) => d[0] <= block, ethPriceI);
      if (ethPriceI === -1) debugger; // eslint-disable-line
      const ethPrice = ethPrices[ethPriceI][1];

      rateI = findLastIndex(rates, (d) => d[0] <= block, rateI);
      currCdp.rate = rates[rateI][1];
      currCdp.debt = Dec(currCdp.normalizedDebt).times(currCdp.rate).div(1e27);

      currPrice = Dec(mPrices[i][1]);

      const ratio = getRatio(currCdp.debt, currCdp.collateral, currPrice);
      if (!startAmount) {
        liquidationPrice = (currCdp.liqRatio * currCdp.debt) / currCdp.collateral;
        startAmountUsd = new Dec(currCdp.collateral).mul(exchangePrice).sub(currCdp.debt).toString();
        startAmount = new Dec(startAmountUsd).div(exchangePrice).toString();
      }
      if (!liquidated && currPrice.lt(liquidationPrice)) {
        liquidated = true;
        liquidationInfo.price = currPrice;
        liquidationInfo.block = block;
      }

      // DEV: Temporarily disabled liquidation check - should be replaced with logic that checks 1h future price
      // if (ratio < cdp.liqRatio) {
      //   break;
      //   // throw new Error('Ratio below 0');
      // }

      if (!stopTriggered && parseInt(currCdp.trailingStop, 10) !== 0) {
        if (optimum === 0) {
          optimum = exchangePrice;
        }
        currentStop = optimum * ((100 - parseInt(currCdp.trailingStop, 10)) / 100);
        if (optimum < exchangePrice) optimum = exchangePrice;
        stops.push([block, currentStop]);
        if (exchangePrice <= currentStop) {
          stopTriggered = true;
          stopTimestamp = block;
          stopPrice = currPrice;
          stopLiqRatio = cdp.liqRatio;

          const collChange = currCdp.debt / currPrice;
          const { gasFeeUsd, serviceFeeUsd } = getFees(currCdp.debt, gasPrice, ethPrice, 'close');
          const action = {
            name: 'Trailing Stop close',
            ratioBefore: 100 * ratio,
            ratioAfter: 0,
            collBefore: currCdp.collateral,
            collAfter: currCdp.collateral - collChange,
            debtBefore: currCdp.debt,
            debtAfter: 0,
            // profitAfter: col.mul(exchangePrice).minus(debt).minus(startAmountUsd).toString(),
            blockNumber: block + 1,
            makerPrice: currPrice.toFixed(2),
            amount: collChange,
            exchangePrice,
            gasFeeUsd,
            serviceFeeUsd,
            collChange,
            debtChange: currCdp.debt,
            date: blockToDate(block + 1).toISOString(),
          };
          actions.push(action);
        }
      }

      if (ratio.gt(currCdp.maxBoost) || ratio.lt(currCdp.minRepay)) {
        const isBoost = ratio.gt(currCdp.maxBoost);
        const amount = getAmount(isBoost ? 'Boost' : 'Repay', currCdp, currPrice, exchangePrice, gasPrice, ethPrice);
        const {
          col, debt, ratio: ratioAfter, serviceFeeUsd, gasFeeUsd, collChange, debtChange,
        } = getValues(isBoost ? 'Boost' : 'Repay', currCdp, amount, currPrice, exchangePrice, gasPrice, ethPrice);
        // if (isBoost && values.col.toNumber() < 0) debugger; // eslint-disable-line

        const action = {
          name: isBoost ? 'Boost' : 'Repay',
          ratioBefore: 100 * ratio,
          ratioAfter: 100 * ratioAfter,
          collBefore: currCdp.collateral,
          collAfter: col.toString(),
          debtBefore: currCdp.debt,
          debtAfter: debt.toString(),
          profitAfter: col.mul(exchangePrice).minus(debt).minus(startAmountUsd).toString(),
          blockNumber: block + 1,
          makerPrice: currPrice.toFixed(2),
          amount,
          exchangePrice,
          gasFeeUsd,
          serviceFeeUsd,
          collChange,
          debtChange,
          date: blockToDate(block + 1).toISOString(),
        };
        actions.push(action);

        currCdp.ratio = ratioAfter.toString();
        currCdp.collateral = col.toString();
        currCdp.debt = debt.toString();
        currCdp.normalizedDebt = debt.times(1e27).div(currCdp.rate).toString();
      }
    }

    const initialBoost = actions[0];
    currCdp.collAfter = new Dec(currCdp.collateral).mul(exchangePrice).sub(currCdp.debt).div(exchangePrice)
      .toString();
    const afterStopLoss = actions.find(({ name }) => name === 'Trailing Stop close') || currCdp;
    const afterLeverageManagement = currCdp;
    const finalCdp = {
      ...cdp,
      price: currPrice.toPrecision(5),
      collateral: currCdp.collateral,
      debt: currCdp.debt.toString(),
      profit: Dec(currCdp.collateral).mul(exchangePrice).minus(currCdp.debt).minus(startAmountUsd)
        .toString(),
      profitWithoutAutomation: Dec(initialBoost?.collAfter || cdp.collateral)
        .mul(exchangePrice).minus(Dec(initialBoost?.debtAfter || 0).div(initialRate).times(currCdp.rate)).minus(startAmountUsd)
        .toString(),
      profitWithoutCdp: Dec(startAmount).mul(exchangePrice).minus(Dec(startAmountUsd)).toString(),
      balanceUsd: new Dec(currCdp.collateral).mul(exchangePrice).sub(currCdp.debt).toString(),
      balanceAsset: new Dec(currCdp.collateral).mul(exchangePrice).sub(currCdp.debt).div(stopTriggered ? stopPrice : exchangePrice)
        .toString(),
      ratio: new Dec(currCdp.collateral).mul(stopTriggered ? stopPrice : currPrice).div(currCdp.debt).toString(),
      liqPrice: new Dec(currCdp.debt).times(stopTriggered ? stopLiqRatio : cdp.liqRatio).div(currCdp.collateral),
      liquidated,
      startAmount,
      startAmountUsd,
      optimum,
      stopTimestamp,
      currentStop,
      results: {
        withoutLeverage: {
          balanceAsset: startAmount,
          balanceUsd: startAmount * currPrice,
        },
        leverageManagement: {
          balanceAsset: afterLeverageManagement.collAfter,
          balanceUsd: afterLeverageManagement.collAfter * currPrice,
        },
        trailingStopAndLeverageManagement: {
          balanceAsset: afterStopLoss.collAfter,
          balanceUsd: afterStopLoss.collAfter * currPrice,
        },
      },
    };
    return {
      actions,
      stops,
      finalCdp,
      liquidated,
      liquidationInfo,
    };
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const parseDataTrailingStop = (_chartOptions, _cdp, _exchPrices, _stops) => {
  const cdp = { ..._cdp };
  const exchPrices = [..._exchPrices];
  const stops = [..._stops];

  const exchPricesForGraph = exchPrices.map(([block, price]) => [blockToDate(block).valueOf(), price]);
  const stopsForGraph = stops.map(([block, price]) => [blockToDate(block).valueOf(), price]);

  const chartOptions = { ..._chartOptions };
  chartOptions.series = [
    {
      animation: false,
      type: 'line',
      name: `${_cdp.asset} Price`,
      data: exchPricesForGraph,
      yAxis: 0,
      color: '#9B51E0',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
        },
      },
    },
    {
      animation: false,
      type: 'line',
      name: `${_cdp.asset} Stop Price`,
      data: stopsForGraph,
      yAxis: 0,
      color: '#39FF14',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
        },
      },
    },
  ];


  chartOptions.xAxis = {
    min: stops[0] ? blockToDate(stops[0][0]).valueOf() : blockToDate(cdp.block).valueOf(),
    max: stops[0] && blockToDate(stops[stops.length - 1][0]).valueOf(),
    plotLines: [{
      dashStyle: 'dash',
      value: blockToDate(cdp.stopTimestamp).valueOf(),
      zIndex: 0,
    }],
  };

  return chartOptions;
};

export const parseData = (_chartOptions, _cdp, simulation, _rates, _exchPrices, _makerPrices) => {
  const cdp = { ..._cdp };
  const rates = [..._rates];
  const exchPrices = [..._exchPrices];
  const makerPrices = [..._makerPrices];
  const stops = [...simulation.stops];

  const initialRateI = findLastIndex(rates, (d) => d[0] <= cdp.block);
  if (initialRateI === -1) debugger; // eslint-disable-line
  const initialRate = rates[initialRateI][1];
  rates.splice(0, initialRateI - 1);
  let normalizedDebt = Dec(cdp.debt).times(1e27).div(initialRate);

  const cdpProfit = [];
  let rateI = 0;
  let exchangePriceI = 0;
  const cdpRatio = makerPrices.map(([block, price]) => {
    if (block < cdp.block) {
      cdpProfit.push([blockToDate(block).valueOf(), 0]);
      return [blockToDate(block).valueOf(), 0];
    }
    rateI = findLastIndex(rates, (d) => d[0] <= block, rateI);
    const rate = rates[rateI][1];
    exchangePriceI = findLastIndex(exchPrices, (d) => d[0] <= block, exchangePriceI);
    const exchangePrice = exchPrices[exchangePriceI][1];
    cdp.debt = Dec(normalizedDebt).times(rate).div(1e27);
    const ratio = (100 * cdp.collateral * price) / cdp.debt;
    cdpProfit.push([blockToDate(block).valueOf(), (cdp.collateral * exchangePrice) - cdp.debt - simulation.finalCdp.startAmountUsd]);
    const blockAction = simulation.actions.find((a) => a.blockNumber === block + 1 && (a.name === 'Boost' || a.name === 'Repay'));
    if (blockAction) {
      cdp.collateral = blockAction.collAfter;
      normalizedDebt = Dec(blockAction.debtAfter).times(1e27).div(rate);
    }
    return [blockToDate(block).valueOf(), ratio];
  });

  const exchPricesForGraph = exchPrices.map(([block, price]) => [blockToDate(block).valueOf(), price]);
  const stopsForGraph = stops.map(([block, price]) => [blockToDate(block).valueOf(), price]);

  const chartOptions = { ..._chartOptions };
  chartOptions.series = [
    {
      animation: false,
      type: 'line',
      name: `${_cdp.asset} Price`,
      data: exchPricesForGraph,
      yAxis: 0,
      color: '#9B51E0',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
        },
      },
    },
    {
      animation: false,
      type: 'line',
      name: 'CDP Ratio',
      id: 'cdpratio',
      data: cdpRatio,
      yAxis: 1,
      color: 'white',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>${nFormatter(this.y)}%</b>`;
        },
      },
    },
    {
      animation: false,
      type: 'line',
      name: 'CDP Profit',
      // visible: false,
      data: cdpProfit,
      yAxis: 2,
      color: '#F2C94C',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>$${nFormatter(this.y)}</b>`;
        },
      },
    },
    {
      animation: false,
      type: 'line',
      name: `${_cdp.asset} Stop Price`,
      data: stopsForGraph,
      yAxis: 0,
      color: '#39FF14',
      tooltip: {
        pointFormatter() {
          return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
        },
      },
    },
    {
      type: 'flags',
      name: 'Boosts',
      data: simulation.actions.filter((a) => a.name === 'Boost').map((a) => ({
        title: ' ',
        text: `${a.name} from ${a.ratioBefore}% to ${a.ratioAfter}% ($${a.makerPrice}/$${a.exchangePrice})`,
        x: blockToDate(a.blockNumber).valueOf(),
      })),
      // onSeries: 'cdpratio',
      shape: `url(${graphPointBoost})`,
      color: '#E4761B',
      stroke: 'none',
      yAxis: 3,
    },
    {
      type: 'flags',
      name: 'Repays',
      data: simulation.actions.filter((a) => a.name === 'Repay').map((a) => ({
        title: ' ',
        text: `${a.name} from ${a.ratioBefore}% to ${a.ratioAfter}% ($${a.makerPrice}/$${a.exchangePrice})`,
        x: blockToDate(a.blockNumber).valueOf(),
      })),
      // onSeries: 'cdpratio',
      shape: `url(${graphPointRepay})`,
      color: '#9B51E0',
      stroke: 'none',
      yAxis: 3,
    },
  ];

  chartOptions.yAxis[1].plotLines = [
    {
      dashStyle: 'dash',
      value: cdp.maxBoost,
      zIndex: 0,
      color: '#37B06F',
    },
    {
      dashStyle: 'dash',
      value: cdp.minRepay,
      zIndex: 0,
      color: '#37B06F',
    },
    {
      dashStyle: 'dash',
      value: cdp.liqRatio * 100,
      zIndex: 0,
      color: '#F55858',
    },
    {
      dashStyle: 'dot',
      value: cdp.optimalRatioBoost,
      zIndex: 0,
    },
    {
      dashStyle: 'dot',
      value: cdp.optimalRatioRepay,
      zIndex: 0,
    },
  ];

  chartOptions.xAxis = {
    min: blockToDate(cdp.block).valueOf(),
    plotLines: [{
      dashStyle: 'dash',
      value: new Date().valueOf(),
      zIndex: 0,
    }],
  };

  return chartOptions;
};

export const getLiqRatio = (ilk) => ilkLiquidationRatios[ilk] || 1.5;

export const getCSV = (actions) => `
date,
action,
collBefore,
collAfter,collChange,debtBefore,debtAfter,debtChange,ratioBefore,ratioAfter,makerPrice,exchangePrice,profitAfter,gasFeeUsd,serviceFeeUsd
${actions.map((a) => `${a.date},${a.name},${a.collBefore},${a.collAfter},${a.collChange},${a.debtBefore},${a.debtAfter},${a.debtChange},${a.ratioBefore},${a.ratioAfter},${a.makerPrice},${a.exchangePrice},${a.profitAfter},${a.gasFeeUsd},${a.serviceFeeUsd}`).join('\n')}
`;

/**
 * Generates future prices with GBM
 *
 * @param T {number} Time horizon
 * @param mu {number} Drift
 * @param sigma {number} Volatility
 * @param S0 {number} Initial price
 * @param dt {number} Size of time steps
 */
export const generateGBM = (T, mu, sigma, S0, dt) => {
  const N = Math.round(T / dt);
  const t = linspace(0, T, N);
  let W = generateRandomNormalDistribution(N);
  W = mulArrBy(cumsum(W), Math.sqrt(dt));
  const X = addArrays(mulArrBy(t, mu - (0.5 * (sigma ** 2))), mulArrBy(W, sigma));
  const S = mulArrBy(exp(X), S0);
  return S;
};
/**
 * Gets last block and price at that block for provided cdp data
 *
 * @param makerPrices {array} Blocks and Maker prices at that block
 * @param exchPrices {array} Blocks and Exchange prices at that block
 * @param extraPrices {array} Extra prices generated from bull and bear runs
 */
export const getLastBlockAndPrice = (makerPrices, exchPrices, extraPrices) => {
  const lastMakerPrice = makerPrices[makerPrices.length - 1];
  const lastExchPrice = exchPrices[exchPrices.length - 1];
  const lastTuple = lastMakerPrice[0] > lastExchPrice[0] ? lastMakerPrice : lastExchPrice;
  const prices = [lastTuple, ...extraPrices];
  return {
    lastBlock: prices[prices.length - 1][0],
    lastPrice: prices[prices.length - 1][1],
  };
};
/**
 * Generates future asset prices based on provided data
 *
 * @param timeInMs {number} Number of milliseconds in timeframe we want to generate data for
 * @param drift {number} Average Yearly Asset Growth, from 0 to infinity
 * @param volatility {number} Asset volatility, from 0 to infinity
 * @param initialPrice {number} Initial price of the asset at the beginning of the timeframe we want future prices for
 * @param initialBlock {number} Initial price's block
 * @param hoursPerIncrement {number} Amount of hours between generated prices
 *
 */
export const generateFuturePrices = (timeInMs, drift, volatility, initialPrice, initialBlock, hoursPerIncrement = 1) => {
  // Time is adjusted for time difference in blockToDate conversion function
  // Initial block should be converted to some date right about now but its actually one day in the past
  // And that causes a bug
  const adjustedTimeInMs = timeInMs + (Date.now() - blockToDate(initialBlock).getTime());
  const ofYear = adjustedTimeInMs / (365 * 24 * 60 * 60 * 1000);
  const dt = 1 / (365 * (24 / hoursPerIncrement));
  const newBlockIncrement = (hoursPerIncrement * 60 * 60 * 1000) / 13310;
  const gbmPrices = generateGBM(ofYear, Math.log(drift), volatility, initialPrice, dt);
  return gbmPrices.map((val, i) => [initialBlock + (newBlockIncrement * (i + 1)), val]);
};
