/* eslint-disable */
export default {
  common: {
    loading: 'Loading...',
    loading_no_dots: 'Loading',
    load: 'Load',
    loading_asset_data: 'Loading asset data...',
    create: 'Create',
    creating: 'Creating',
    generate: 'Generate',
    generating: 'Generating',
    connect: 'Connect',
    connecting: 'Connecting',
    connected: 'Connected',
    buy: 'Buy',
    buying: 'Buying',
    enable: 'Enable',
    enabling: 'Enabling',
    exchange: 'Exchange',
    exchanging: 'Exchanging',
    supply: 'Supply',
    supplying: 'Supplying',
    borrow: 'Borrow',
    borrowing: 'Borrowing',
    supplied: 'Supplied',
    withdraw: 'Withdraw',
    withdrawing: 'Withdrawing',
    transfer: 'Transfer',
    transferring: 'Transferring',
    boost: 'Boost',
    boosting: 'Boosting',
    repay: 'Repay',
    repaying: 'Repaying',
    payback: 'Payback',
    sending: 'Sending',
    send: 'Send',
    subscribe: 'Subscribe',
    subscribing: 'Subscribing',
    paying_back: 'Paying back',
    add_collateral: 'Add collateral',
    adding_collateral: 'Adding collateral',
    migrate: 'Migrate',
    migrating: 'Migrating',
    move: 'Move',
    moving: 'Moving',
    after: 'After',
    saver: 'Saver',
    manage: 'Manage',
    success: 'Success!',
    coming_soon: '%feature coming soon...',
    short: 'Short',
    leverage: 'Leverage',
    in_a_week: 'In a week',
    in_a_month: 'In a month',
    in_a_year: 'In a year',
    price: 'Price',
    collateral_factor: 'Collateral factor',
    market_liquidity: 'Market liquidity',
    utilization: 'Utilization',
    market_info: 'Market info:',
    insured: 'Insured:',
    supply_rate: 'Supply rate:',
    borrow_rate: 'Borrow rate:',
    choose_asset: 'Choose asset:',
    stability_fee: 'Stability Fee',
    liquidation_price: 'Liquidation price:',
    current_slippage: 'Current slippage:',
    action: 'Action',
    overview: 'Overview',
    ratio: 'Ratio',
    pages: 'Pages:',
    choose: 'Choose',
    debt: 'Debt',
    account_type: 'Account type:',
    path: 'Path:',
    minimum_amount: 'Minimum amount:',
    from_token: 'From %token',
    to_token: 'To %token',
    to: 'To',
    from: 'From',
    amount: 'Amount',
    name: 'Name',
    balance: 'Balance',
    approve: 'Approve',
    approving: 'Approving',
    approved: 'Approved',
    close: 'Close',
    closing: 'Closing',
    logging_in: 'Logging in...',
    safe: 'Safe',
    risky: 'Risky',
    gamble: 'Gamble',
    amount_of_token: 'Amount of %token:',
    token_amount_in: '%first_token amount in %second_token:',
    executing_action: 'Executing action',
    new_owner: 'New owner',
    welcome_back: 'Welcome back!',
    contact_us: 'Contact us',
    token_debt: '%token debt',
    with: 'with',
    at_price: 'at price',
    automate: 'Automate',
    reminder: 'Reminder',
    info: 'Info',
    legacy: 'Legacy',
    live: 'Live',
    custom_type: 'Custom type',
    max: 'max %value',
    min: 'min %value',
    max_val: 'Max: %value',
    min_val: 'Min: %value',
    email: 'Email',
    email_address: 'Email address',
    follow_us: 'Follow us:',
    protocols: 'Protocols',
    wallets: 'Wallets',
    repay_to: 'Repay to',
    ratio_over: 'Ratio over',
    boost_to: 'Boost to',
    current_price: 'Current price',
    collateral: 'Collateral',
    collateral_in: 'Collateral in',
    set_up: 'Set up',
    how_does_it_work: 'How does it work?',
    history: 'History',
    type: 'Type',
    date: 'Date',
    transaction: 'Transaction',
    load_more: 'Load more',
    protection: 'Protection',
    update: 'Update',
    updating: 'Updating',
    disable: 'Disable',
    disabling: 'Disabling',
    advanced: 'Advanced',
    cancel: 'Cancel',
    message: 'Message',
    accept: 'Accept',
    import: 'Import',
    importing: 'Importing',
    soon: 'soon',
    no_notifications_received: 'No notifications received.',
    go_back_to_dashboard: 'Go back to dashboard',
    your_feedback_helps_us: 'Your feedback helps us improve DeFi Explore.',
    create_dsproxy: 'Create DSProxy',
    for: 'for',
    account: 'account',
    ds_proxy: 'DSProxy',
    upgrade: 'Upgrade',
    upgrading: 'Upgrading',
    migration: 'Migration',
    your_asset_balance: 'Your %asset balance',
    ratio_after: 'Ratio after',
    convert: 'Convert',
    converting: 'Converting',
    back: 'Back',
    next: 'Next',
    borrowed: 'Borrowed',
    please_note_no_change: 'Please note that this action cannot be undone.',
    select: 'Select',
    selected: 'Selected',
    subscribed: 'Subscribed',
    next_price_explanation: 'The MCD protocol will be updated with this price after %timestamp \nNext price: $%price\nNext ratio: %ratio%',
  },
  maker: {
    amount_of_dai_generated: 'Amount of %daiLabel generated from the CDP',
    cdp_collateral_asset: 'Collateral for the CDP is stored as %asset',
    lifetime_profit: 'Lifetime Profit',
    lifetime_profit_descr: 'Current CDP balance (collateral - debt)\n+ value exported (generated DAI + withdrawn collateral)\n- total value added (added collateral + repaid DAI)',
    ratio_change: 'Ratio Change',
  },
  maker_actions: {
    'add-collateral': 'Add Collateral',
    'create': 'Create',
    'basic_create': 'Create',
    'leveraged_create': 'Leveraged Create',
    'withdraw-collateral': 'Withdraw Collateral',
    'generate-dai': 'Generate DAI',
    'payback-dai': 'Pay Back DAI',
    'add-collateral-draw-dai': 'Add & Generate',
    'payback-dai-withdraw-collateral': 'Pay Back & Withdraw',
    'boost': 'Boost',
    'repay': 'Repay',
    'liquidation': 'Liquidation',
    'close': 'Close',
    'borrow_supply': 'Borrow & Supply',
    'withdraw_payback': 'Withdraw & Pay Back',
    'transfer': 'Transfer',
    'fork': 'Fork',
    'unknown': 'Unknown',
  },
  tx_fail_reasons: {
    'vat/dust': 'Transaction caused CDP debt to be lowered below 20 DAI minimum.',
    'vat/not-safe': 'Transaction caused CDP ratio to drop under safe minimum.',
    'vat/ceiling-exceeded': 'Global DAI maximum reached.',
    'dai/insufficient-allowance': 'Insufficient DAI allowance',
    'dai/insufficient-balance': 'Insufficient DAI balance',
    'slippage hit': 'Price slippage hit when exchanging assets.',
    'out_of_gas': 'Transaction ran out of gas.',
  },
  tx_fail_solutions: {
    'vat/dust': 'Keep your CDP debt above 20 DAI or pay it off completely.',
    'vat/not-safe': 'Keep your CDP ratio above the liquidation limit.',
    'vat/ceiling-exceeded': '',
    'dai/insufficient-allowance': 'Approve the called contract for the required amount of DAI.',
    'dai/insufficient-balance': 'Acquire enough DAI to perform the desired action.',
    'slippage hit': 'Try increasing the slippage limit or exchanging a smaller amount. Make sure the gas price isn\'t causing your tx to stall.',
    'out of gas': 'Try increasing the gas limit.',
  },
  misc: {
    copied_to_clipboard: 'Copied to clipboard',
  },
  error: {
    slippage_hit: 'The transaction hit the slippage limit, hence execution reverted.',
    out_of_gas: 'Transaction ran out of gas.',
  }
};
