/* eslint-disable */
import React from 'react';

export default function Caret({ size = 7, color = '#37B06F', orientation = 'down' }) {
  return (
    <svg
      style={{
        transform: `rotate(${{ down: 0, left: 90, up: 180, right: 270 }[orientation]}deg)`,
        verticalAlign: 'middle'
      }}
      width={size} height={size} viewBox={`0 0 7 7`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.46409 6L-1.09196e-05 -6.05683e-07L6.92819 0L3.46409 6Z" fill={color} />
    </svg>
  );
}
