import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAssetInfo } from '@defisaver/tokens';
import t from 'translate';
import DataItem from '../../MakerManage/MakerManageDataItems/DataItem/DataItem';
import { nFormatter, numberWithCommas, blockToDate } from '../../../services/utils';
import AutomationShield from '../../Decorative/AutomationShield/AutomationShield';
import InfoItem from '../../MakerManage/MakerManageMcd/MakerManageMcdInfoItems/InfoItem/InfoItem';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';

const Value = ({ value, tooltip }) => (
  <TooltipWrapper title={tooltip || `$${numberWithCommas(value)}`}>
    <span className={`value ${(parseFloat(value) < 0 ? 'falling' : 'rising')}`}>{value < 0 && '-'}${ nFormatter(Math.abs(value), 2) }</span>
  </TooltipWrapper>
);
Value.defaultProps = {
  tooltip: '',
};
Value.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tooltip: PropTypes.string,
};

class TrailingStopSimulatedOutput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { cdp } = this.props;
    return (
      <div className="cdp-simulated-output-wrapper dashboard-page-wrapper">
        <div className="main-section-wrapper">
          <div className="main-subsections-wrapper">
            <div className="main-subsection">
              <div className="maker-manage-data-items-wrapper">
                <DataItem
                  label={t('common.collateral')}
                  value={cdp.collateral}
                  symbol={cdp.asset}
                  tooltip={`${numberWithCommas(cdp.collateral, 2)} ${cdp.asset}\n$${numberWithCommas(cdp.collateral * cdp.price, 2)}`}
                  smallSymbol
                />
              </div>
              <div className="maker-manage-info-items-wrapper">
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label={t('common.current_price')}
                  value={cdp.price}
                  prefixSymbol="$"
                />
                { cdp.optimum !== 0 && (
                  <InfoItem
                    icon={getAssetInfo(cdp.asset).icon}
                    label="Optimum"
                    value={cdp.optimum}
                    prefixSymbol="$"
                  />
                )}
                { cdp.currentStop !== 0 && (
                  <InfoItem
                    icon={getAssetInfo(cdp.asset).icon}
                    label="Stop price"
                    value={cdp.currentStop}
                    prefixSymbol="$"
                  />
                )}
                <div>{ cdp.stopTimestamp !== 0 ? `Stop time: ${blockToDate(cdp.stopTimestamp).toString()}` : 'No Trailing Stop'}</div>
              </div>
            </div>

            <div className="maker-manage-profit-calc-wrapper">
              <div className="flex">
                <div className="labels">
                  <div className="row label"><TooltipWrapper title="The profit made by triggering the Trailing stop over the simulated period."><i className="icon icon-Info-circle" /> Trailing Stop profit: </TooltipWrapper></div>
                  <div className="row label"><TooltipWrapper title="The profit from simply holding ETH over the simulated period, based on the balance at the start of the simulation."><i className="icon icon-Info-circle" /> Holding {cdp.asset} profit: </TooltipWrapper></div>
                  <div className="row label"><TooltipWrapper title="The final balance difference made by having trailing stop enabled for this position."><i className="icon icon-Info-circle" /> Automation difference: </TooltipWrapper></div>
                </div>
                <div className="values">
                  <div className="row"><Value value={cdp.profit} /></div>
                  <div className="row"><Value value={cdp.profitWithoutStop} /></div>
                  <div className="row">
                    <Value value={cdp.profit - cdp.profitWithoutStop} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TrailingStopSimulatedOutput.propTypes = {
  cdp: PropTypes.object.isRequired,
};

TrailingStopSimulatedOutput.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrailingStopSimulatedOutput);
