import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { getLiqudationData } from '../../actions/apiActions';
import IconExternal from '../Decorative/IconExternal';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import Countdown from '../Decorative/Countdown';
import {
  nFormatter, parseTimestamp, round, shortAddress,
} from '../../services/utils';
import './Liquidation.scss';

class Liquidation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.getLiqudationData(match.params.cdpType, match.params.liqId, match.params.biteHash);
  }

  render() {
    const {
      liquidationData, liquidationDataLoading, liquidationDataError, match,
    } = this.props;

    const highestTend = liquidationData?.tend[liquidationData.tend.length - 1]?.daiAmount;
    const lowestDent = liquidationData?.dent[liquidationData.dent.length - 1]?.collAmount;
    const phase1Active = liquidationData && !liquidationData.finished && round(highestTend) !== round(liquidationData.debt);
    const phase2Active = liquidationData && !liquidationData.finished && round(highestTend) === round(liquidationData.debt);
    let auctionEnds = new Date();
    if (liquidationData) {
      auctionEnds = new Date(
        new Date(
          liquidationData.tend[0]?.timestamp || liquidationData.timestamp,
        ).valueOf()
          + (6 * 60 * 60 * 1000),
      );
    }
    const phase3Active = phase2Active && auctionEnds < new Date();

    return (
      <div className="liquidation-wrapper">
        <div className="width-container">
          {
            liquidationData && (
              <div>
                <div className="liq-header">
                  <h1>CDP
                    <Link to={`/cdp/${liquidationData.cdpId}`}>#{liquidationData.cdpId}</Link> - Liquidation { match.params.liqId }
                  </h1>
                  <div className="data">
                    <div className="label">Auction { liquidationData.finished ? 'finished' : 'open' }</div>
                    <div className="value">
                      {
                          !liquidationData.finished && (
                            <>Selling off <span className="white-text">{nFormatter(liquidationData.collateral)} {liquidationData.cdpType}</span> for <span className="white-text">{nFormatter(liquidationData.debt)} DAI</span></>
                          )
                        }
                      {
                          liquidationData.finished && (
                            <>Sold off <span className="white-text">{nFormatter(liquidationData.soldColl)} {liquidationData.cdpType}</span> for <span className="white-text">{nFormatter(liquidationData.soldFor)} DAI</span></>
                          )
                        }
                    </div>
                  </div>
                  {
                    (phase1Active || phase2Active) && (
                      <div className="data">
                        <div className="label">Auction ends:</div>
                        <div className="value">
                          <Countdown date={auctionEnds} /> ({parseTimestamp(auctionEnds)})
                        </div>
                      </div>
                    )
                  }
                  {
                    liquidationData.finished && (
                      <div className="data">
                        <div className="label">Auction ended:</div>
                        <div className="value">
                          {parseTimestamp(auctionEnds)}
                        </div>
                        <div className="label">
                          Are you the auction winner?{' '}
                          <a target="_blank" rel="noopener noreferrer" href={`https://defisaver.com/makerdao/liquidation/${liquidationData.cdpType}/${liquidationData.liqId}/${liquidationData.biteHash}`} className="green-text">Claim your winnings.</a>
                        </div>
                      </div>
                    )
                  }
                  {
                    !liquidationData.finished && (
                      <div className="phases-wrapper">
                        <div>
                          <h1>Phase 1: {phase1Active ? 'Active' : 'Finished'}</h1>
                          <div className="data">
                            <div className="label">Highest DAI bid:</div>
                            <div className="value white-text">
                              {
                                liquidationData.tend.length > 0 && (
                                  <div>
                                    <b>{nFormatter(highestTend)} DAI</b> ({nFormatter(round(100 * (highestTend / liquidationData.debt)))}%)
                                  </div>
                                )
                              }
                              {liquidationData.tend.length === 0 && (<div>No bids yet</div>)}
                            </div>
                          </div>
                          {
                            phase1Active && <a target="_blank" rel="noopener noreferrer" href={`https://defisaver.com/makerdao/liquidation/${liquidationData.cdpType}/${liquidationData.liqId}/${liquidationData.biteHash}`} className="button green">Participate</a>
                          }
                        </div>
                        <div>
                          <h1>Phase 2: {phase2Active ? 'Active' : 'Pending'}</h1>

                          <div className="data">
                            <div className="label">Lowest {liquidationData.cdpType} bid:</div>
                            <div className="value white-text">
                              {
                                liquidationData.dent.length > 0 && (
                                  <div>
                                    <b>{nFormatter(lowestDent)} {liquidationData.cdpType}</b>
                                    ({nFormatter(round(100 * (lowestDent / liquidationData.collateral)))}%)
                                  </div>
                                )
                              }
                              {liquidationData.dent.length === 0 && (<div>No bids yet</div>)}
                            </div>
                          </div>
                          {
                            phase2Active && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://defisaver.com/makerdao/liquidation/${liquidationData.cdpType}/${liquidationData.liqId}/${liquidationData.biteHash}`}
                                className="button green"
                              >
                                { phase3Active ? 'Finalize' : 'Participate' }
                              </a>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                </div>

                <h2>Phase 1 (DAI Auction - Tend)</h2>
                {liquidationData.tend.length === 0 && <span>No bids</span>}
                {
                  liquidationData.tend.length > 0 && (
                    <div className="styled-table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Bidder</th>
                            <th>DAI Amount</th>
                            <th>{liquidationData.cdpType} Amount</th>
                            <th>Price</th>
                            <th>Timestamp</th>
                            <th>Tx</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          liquidationData.tend.map((bid) => (
                            <tr key={bid.txHash}>
                              <td><Link to={`/address/${bid.sender}`}>{shortAddress(bid.sender)}</Link></td>
                              <td>
                                {nFormatter(bid.daiAmount)}/{nFormatter(liquidationData.debt)}
                                DAI
                                ({nFormatter(round(100 * (bid.daiAmount / liquidationData.debt)))}%)
                              </td>
                              <td>{nFormatter(liquidationData.collateral)} {liquidationData.cdpType}</td>
                              <td>{nFormatter(bid.buyPrice)} DAI/{liquidationData.cdpType}</td>
                              <td>{parseTimestamp(bid.timestamp)}</td>
                              <td>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://etherscan.io/tx/${bid.txHash}`}
                                >
                                  <IconExternal size={12} />
                                </a>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  )
                }

                <br />
                <br />

                <h2>Phase 2 (Collateral Auction - Dent)</h2>

                {liquidationData.dent.length === 0 && <span>No bids</span>}
                {
                  liquidationData.dent.length > 0 && (
                    <div className="styled-table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Bidder</th>
                            <th>DAI Amount</th>
                            <th>{liquidationData.cdpType} Amount</th>
                            <th>Price</th>
                            <th>Timestamp</th>
                            <th>Tx</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          liquidationData.dent.map((bid) => (
                            <tr key={bid.txHash}>
                              <td><Link to={`/address/${bid.sender}`}>{shortAddress(bid.sender)}</Link></td>
                              <td>
                                {nFormatter(bid.daiAmount)}/{nFormatter(liquidationData.debt)}
                                DAI
                                ({nFormatter(round(100 * (bid.daiAmount / liquidationData.debt)))}%)
                              </td>
                              <td>
                                {nFormatter(bid.collAmount)}/{nFormatter(liquidationData.collateral)}
                                {liquidationData.cdpType}
                                ({nFormatter(round(100 * (bid.collAmount / liquidationData.collateral)))}%)
                              </td>
                              <td>{nFormatter(bid.buyPrice)} DAI/{liquidationData.cdpType}</td>
                              <td>{parseTimestamp(bid.timestamp)}</td>
                              <td>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://etherscan.io/tx/${bid.txHash}`}
                                >
                                  <IconExternal size={12} />
                                </a>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  )
                }
                <br />
                <br />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

Liquidation.propTypes = {
  match: PropTypes.object.isRequired,
  getLiqudationData: PropTypes.func.isRequired,
  liquidationData: PropTypes.object,
  liquidationDataLoading: PropTypes.bool.isRequired,
  liquidationDataError: PropTypes.string.isRequired,
};

Liquidation.defaultProps = {
  liquidationData: null,
};

const mapStateToProps = ({ general }) => ({
  liquidationData: general.liquidationData,
  liquidationDataLoading: general.liquidationDataLoading,
  liquidationDataError: general.liquidationDataError,
});

const mapDispatchToProps = {
  getLiqudationData,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(withRouter(Liquidation)), ErrorFallback);
