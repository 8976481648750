import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAssetInfo } from '@defisaver/tokens';
import t from 'translate';
import DataItem from '../../MakerManage/MakerManageDataItems/DataItem/DataItem';
import {
  nFormatter, numberWithCommas, blockToDate, parseTimestamp,
} from '../../../services/utils';
import AutomationShield from '../../Decorative/AutomationShield/AutomationShield';
import InfoItem from '../../MakerManage/MakerManageMcd/MakerManageMcdInfoItems/InfoItem/InfoItem';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';
import './CdpSimulatedOutput.scss';

const Value = ({ value, tooltip }) => (
  <TooltipWrapper title={tooltip || `$${numberWithCommas(value)}`}>
    <span className={`value ${(parseFloat(value) < 0 ? 'falling' : 'rising')}`}>{value < 0 && '-'}${ nFormatter(Math.abs(value), 2) }</span>
  </TooltipWrapper>
);
Value.defaultProps = {
  tooltip: '',
};
Value.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tooltip: PropTypes.string,
};

class CdpSimulatedOutput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { cdp } = this.props;
    return (
      <div className="cdp-simulated-output-wrapper dashboard-page-wrapper">
        <div className="main-section-wrapper">
          <div className="main-subsections-wrapper">
            <div className="main-subsection">
              <div className="maker-manage-data-items-wrapper">
                <DataItem
                  label={t('common.collateral')}
                  value={cdp.collateral}
                  symbol={cdp.asset}
                  tooltip={`${numberWithCommas(cdp.collateral, 2)} ${cdp.asset}\n$${numberWithCommas(cdp.collateral * cdp.price, 2)}`}
                  smallSymbol
                />

                <DataItem
                  label={t('common.debt')}
                  value={cdp.debt}
                  symbol="DAI"
                  smallSymbol
                />

                <DataItem
                  label={`${t('common.ratio')} (${t('common.min_val', { '%value': `${Math.floor(cdp.liqRatio * 100)}%` })})`}
                  value={cdp.ratio * 100}
                  symbol="%"
                  showRatioCircle
                  highlighted={cdp.subscribedToAutomation}
                  active={cdp.subscribedToAutomation}
                  valueAddon={cdp.subscribedToAutomation ? <TooltipWrapper title="Protected by DeFi Saver Automation"><AutomationShield active /></TooltipWrapper> : ''}
                />
              </div>
              <div className="maker-manage-info-items-wrapper info-margin">
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label={t('common.current_price')}
                  value={cdp.price}
                  prefixSymbol="$"
                />
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label={t('common.liquidation_price')}
                  value={cdp.liqPrice}
                  prefixSymbol="$"
                />
                { cdp.optimum !== 0 && (
                  <InfoItem
                    icon={getAssetInfo(cdp.asset).icon}
                    label="Peak price"
                    value={cdp.optimum}
                    prefixSymbol="$"
                  />
                )}
                { cdp.currentStop !== 0 && (
                  <InfoItem
                    icon={getAssetInfo(cdp.asset).icon}
                    label="Stop price"
                    value={cdp.currentStop}
                    prefixSymbol="$"
                  />
                )}
              </div>
              <div className="info-margin">{ cdp.stopTimestamp !== 0 ? `Stop time: ${parseTimestamp(blockToDate(cdp.stopTimestamp))}` : 'No Trailing Stop'}</div>
              <div className="maker-manage-info-items-wrapper">
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label="Hold balance"
                  value={cdp.startAmount}
                  symbol={cdp.asset}
                  additionalValue={cdp.startAmountUsd}
                  additionalValueLabel="USD"
                  additionalValuePrefixSymbol="$"
                />
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label="End balance (lev. mgmt.)"
                  value={cdp.results.leverageManagement.balanceAsset}
                  symbol={cdp.asset}
                  additionalValue={cdp.results.leverageManagement.balanceUsd}
                  additionalValueLabel="USD"
                  additionalValuePrefixSymbol="$"
                />
                <InfoItem
                  icon={getAssetInfo(cdp.asset).icon}
                  label="End balance (lev. mgmt. + trailing stop)"
                  value={cdp.results.trailingStopAndLeverageManagement.balanceAsset}
                  symbol={cdp.asset}
                  additionalValue={cdp.results.trailingStopAndLeverageManagement.balanceUsd}
                  additionalValueLabel="USD"
                  additionalValuePrefixSymbol="$"
                />
              </div>
            </div>

            <div className="maker-manage-profit-calc-wrapper">
              <div className="flex">
                <div className="labels">
                  <div className="row label"><TooltipWrapper title="The profit made by the Automated position over the simulated period. Includes 0.3% service fee per automated adjustment and the transaction costs based on the entered gas price"><i className="icon icon-Info-circle" /> Automated CDP profit: </TooltipWrapper></div>
                  <div className="row label"><TooltipWrapper title="The profit from simply holding ETH over the simulated period, based on the balance at the start of the simulation."><i className="icon icon-Info-circle" /> Holding {cdp.asset} profit: </TooltipWrapper></div>
                  <div className="row label"><TooltipWrapper title="The final balance difference made by having automated leveraging enabled for this position."><i className="icon icon-Info-circle" /> Automation difference: </TooltipWrapper></div>
                </div>
                <div className="values">
                  <div className="row"><Value value={cdp.profit} /></div>
                  {/* <div className="row"> */}
                  {/*  { */}
                  {/*    cdp.liquidated */}
                  {/*      ? <span className="value falling">Liquidated</span> */}
                  {/*      : <Value value={cdp.profitWithoutAutomation} /> */}
                  {/*  } */}
                  {/* </div> */}
                  <div className="row"><Value value={cdp.profitWithoutCdp} /></div>
                  <div className="row">
                    {/* { */}
                    {/*  cdp.liquidated */}
                    {/*    ? <span className="value">N/A</span> */}
                    {/*    : <Value value={cdp.profit - cdp.profitWithoutCdp} /> */}
                    {/* } */}
                    <Value value={cdp.profit - cdp.profitWithoutCdp} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CdpSimulatedOutput.propTypes = {
  cdp: PropTypes.object.isRequired,
};

CdpSimulatedOutput.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CdpSimulatedOutput);
