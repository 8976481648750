import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';

import './Loader.scss';

const Loader = ({
  message, showDefaultMessage, color, mini,
}) => (
  <div className="loader-wrapper">
    <div className={`loader ${mini && 'mini'}`}>
      { showDefaultMessage && (<div className="loader-text">{t('common.loading')}</div>) }
      <svg className="circular" viewBox="25 25 50 50">
        <circle stroke={color} className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>

    { message && (<div className="message-wrapper">{ message }</div>) }
  </div>
);

Loader.defaultProps = {
  message: '',
  showDefaultMessage: true,
  color: '#5BB4A7',
  mini: false,
};

Loader.propTypes = {
  message: PropTypes.string,
  showDefaultMessage: PropTypes.bool,
  color: PropTypes.string,
  mini: PropTypes.bool,
};

export default Loader;
