import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ManageCdpPanel.scss';

class ManageCdpPanel extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { cdpId } = this.props;
    return (
      <div className="manage-cdp-panel-wrapper">
        <div>
          <h1>Is this your CDP?</h1>
          <p>Manage your CDP with DeFi Saver, an advanced management dashboard for decentralized finance.</p>
        </div>
        <div>
          <a href={`https://defisaver.com/makerdao/manage/${cdpId}`} target="_blank" rel="noopener noreferrer" className="button green">Open Dashboard</a>
        </div>
      </div>
    );
  }
}


ManageCdpPanel.propTypes = {
  cdpId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ManageCdpPanel;
