import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { getGlobalInfo, getTopCdps } from '../../actions/apiActions';
import './HomePage.scss';
import MakerManage from '../MakerManage/MakerManage';
import MiniStatWrapper from './MiniStatWrapper/MiniStatWrapper';
import CdpSearchBox from '../CdpSearchBox/CdpSearchBox';
import TopCdps from './TopCdps/TopCdps';
import AddressInfo from '../AddressInfo/AddressInfo';
import TxInfo from '../TxInfo/TxInfo';

const HomePage = (props) => {
  const {
    globalInfo,
    globalInfoLoading,
    searchDataFetched,
    searchDataObsolete,
    searchQuery,
  } = props;

  useEffect(() => {
    if (!globalInfo?.numCdps) props.getGlobalInfo();
    props.getTopCdps(props.topCdps.pageNumber, 20, props.topCdps.order, props.topCdps.sortBy, props.topCdps.filters);
  }, []);

  return (
    <div className="homepage-wrapper">
      <div className="width-container">
        <CdpSearchBox />
        <div className={`stats-header ${(searchDataFetched || searchQuery) ? 'hidden' : ''}`}>
          <div>
            <h1>Explore MakerDAO Vaults</h1>
            <p>
              Find current and historical information on collateralised debt positions in the MakerDAO protocol.
            </p>
          </div>

          <div className="stats-wrapper">
            <MiniStatWrapper value={globalInfo.numCdps} primaryLabel="Vaults" secondaryLabel="Total" loading={globalInfoLoading} />
            <MiniStatWrapper value={globalInfo.debt} primaryLabel="DAI" secondaryLabel="Debt" loading={globalInfoLoading} round />
            <Link to="/stats">
              <MiniStatWrapper primaryLabel="Stats" secondaryLabel="View More" />
            </Link>
          </div>
        </div>
        <Switch>
          <>
            <div className={`result-wrapper ${searchDataObsolete ? 'obsolete' : ''}`}>
              <Route path="/cdp/:cdpId" component={MakerManage} />
              <Route path="/address/:address" component={AddressInfo} />
              <Route path="/tx/:txHash" component={TxInfo} />
            </div>
            <Route exact path="/" component={TopCdps} />
          </>
        </Switch>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  getGlobalInfo: PropTypes.func.isRequired,
  getTopCdps: PropTypes.func.isRequired,
  globalInfo: PropTypes.object.isRequired,
  globalInfoLoading: PropTypes.bool.isRequired,
  searchDataFetched: PropTypes.bool.isRequired,
  searchDataObsolete: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  topCdps: PropTypes.object.isRequired,
};

HomePage.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  globalInfo: general.globalInfo,
  globalInfoLoading: general.globalInfoLoading,
  searchDataFetched: general.searchDataFetched,
  searchDataObsolete: general.searchDataObsolete,
  searchQuery: general.searchQuery,
  topCdps: general.topCdps,
});

const mapDispatchToProps = {
  getGlobalInfo,
  getTopCdps,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
