import { getAssetInfo, ilkToAsset } from '@defisaver/tokens';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { getGlobalInfo } from '../../actions/apiActions';
import { nFormatter, numberWithCommas } from '../../services/utils';
import Caret from '../Decorative/Caret';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import ActionsOverTimeGraph from './ActionsOverTimeGraph/ActionsOverTimeGraph';
import './Stats.scss';

class Stats extends Component {
  componentDidMount() {
    if (!this.props.globalInfo?.numCdps) this.props.getGlobalInfo();
  }

  render() {
    const { globalInfo } = this.props;

    let ilks = [];
    let collateralization = 0;
    let collSum = 0;
    let debtSum = 0;
    const tokenWillFall = {};

    if (globalInfo.tokenData) {
      ilks = Object.keys(globalInfo.tokenData);

      ilks.forEach((t) => {
        collSum += globalInfo.tokenData[t].collateral * globalInfo.tokenData[t].price;
        debtSum += globalInfo.tokenData[t].debt;
        tokenWillFall[t] = globalInfo.tokenData[t].price > globalInfo.tokenData[t].futurePrice;
      });

      collateralization = collSum / (debtSum / 100);
    }

    return (
      <div className="stats-page-wrapper">
        <div className="width-container">
          <h1>Global Stats</h1>
          <div className="section-wrapper">

            <div className="stats-break">{getAssetInfo('MKR')?.icon()} Maker System</div>
            <div className="stats-grid-row">
              <div className="stat-cell-wrapper">
                <p>Active Vaults:</p>
                <h4>{numberWithCommas(globalInfo.activeCdps, 0)}</h4>
                <p className="sublabel">Total: <span>{numberWithCommas(globalInfo.numCdps, 0)}</span></p>
              </div>
              <div className="stat-cell-wrapper">
                <p>Collateralization:</p>
                <h4>{collateralization.toPrecision(5)}%</h4>
              </div>
              <div className="stat-cell-wrapper">
                <p>DAI Debt:</p>
                <h4>{numberWithCommas(debtSum, 0)}<span className="label"> DAI</span></h4>
              </div>
            </div>

            {
              ilks
                .sort((a, b) => globalInfo.tokenData[b].debt - globalInfo.tokenData[a].debt)
                .map((ilk) => (
                  <React.Fragment key={ilk}>
                    <div className="stats-break">{getAssetInfo(ilkToAsset(ilk)).icon?.()} {new RegExp(/.*-[A-Z]/).test(ilk) ? ilk : `${ilk}-A`} Collateral</div>
                    <div className="stats-grid-row">
                      <div className="stat-cell-wrapper">
                        <p>Active Vaults:</p>
                        <h4>
                          {numberWithCommas(globalInfo.tokenData[ilk].activeCdps, 0)}
                        </h4>
                        <p className="sublabel">Total: <span>{numberWithCommas(globalInfo.tokenData[ilk].cdps, 0)}</span></p>
                      </div>
                      <div className="stat-cell-wrapper">
                        <p>Price:</p>
                        <h4>
                          <TooltipWrapper title={globalInfo.tokenData[ilk].price}>
                            ${parseFloat(globalInfo.tokenData[ilk].price).toPrecision(5)}
                          </TooltipWrapper>
                        </h4>
                        <p className="sublabel">
                          Next: <Caret orientation={tokenWillFall[ilk] ? 'down' : 'up'} color={tokenWillFall[ilk] ? '#EB5757' : '#37B06F'} />
                          <TooltipWrapper title={globalInfo.tokenData[ilk].futurePrice}>
                            ${parseFloat(globalInfo.tokenData[ilk].futurePrice).toPrecision(5)}
                          </TooltipWrapper>
                        </p>
                      </div>
                      <div className="stat-cell-wrapper">
                        <p>Stability Fee:</p>
                        <TooltipWrapper title={globalInfo.tokenData[ilk].stabilityFee}>
                          <h4>{globalInfo.tokenData[ilk].stabilityFee.toFixed(2)}%</h4>
                        </TooltipWrapper>
                        <p className="sublabel">Min. debt: <span>{numberWithCommas(globalInfo.tokenData[ilk].minDebt, 0)} DAI</span></p>
                      </div>
                      <div className="stat-cell-wrapper">
                        <p>Total {ilkToAsset(ilk)}:</p>
                        <h4>
                          <TooltipWrapper title={globalInfo.tokenData[ilk].collateral}>
                            {numberWithCommas(globalInfo.tokenData[ilk].collateral, 0)}<span className="label"> {ilk.substr(0, ilk.length - 2)}</span>
                          </TooltipWrapper>
                        </h4>
                        <p className="sublabel">USD: <span>${numberWithCommas(globalInfo.tokenData[ilk].collateral * globalInfo.tokenData[ilk].price, 0)}</span></p>
                      </div>
                      <div className="stat-cell-wrapper">
                        <p>DAI Debt:</p>
                        <h4>
                          <TooltipWrapper title={globalInfo.tokenData[ilk].debt}>
                            {numberWithCommas(globalInfo.tokenData[ilk].debt, 0)}<span className="label"> DAI</span>
                          </TooltipWrapper>
                        </h4>
                        <p className="sublabel">
                          Ceiling:{' '}
                          <TooltipWrapper title={globalInfo.tokenData[ilk].debtCeiling}>
                            {nFormatter(globalInfo.tokenData[ilk].debtCeiling, 2)}
                          </TooltipWrapper>
                          {' '}({nFormatter(100 * (globalInfo.tokenData[ilk].debt / globalInfo.tokenData[ilk].debtCeiling), 1)}%)
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))
            }
          </div>

          <div className="section-wrapper">
            <ActionsOverTimeGraph />
          </div>
        </div>
      </div>
    );
  }
}

Stats.propTypes = {
  globalInfo: PropTypes.object.isRequired,
  // globalInfoLoading: PropTypes.bool.isRequired,
  getGlobalInfo: PropTypes.func.isRequired,
};

Stats.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  globalInfo: general.globalInfo,
});

const mapDispatchToProps = {
  getGlobalInfo,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(Stats), ErrorFallback);
