import Dec from 'decimal.js';

Dec.set({
  rounding: Dec.ROUND_DOWN,
  toExpPos: 9e15,
  toExpNeg: -9e15,
});

/**
 * Parse error object to string
 * @param err {Error}
 * @returns {string}
 */
export const parseError = (err) => (err.message ? err.message.toString() : err.toString());

/**
 * Formats long numbers with commas as thousands separators
 *
 * @param x {String || Number}
 * @param decimals
 * @param removeTrailingZeroes
 * @return {String}
 */
export const numberWithCommas = (x, decimals = 8, removeTrailingZeroes = true) => {
  if (!Number.isFinite(parseFloat(x))) return '0';
  if (parseFloat(x).toString() === '0') return '0';

  const parts = Dec(x).toFixed(decimals).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let formatted = parts.join('.');
  if (removeTrailingZeroes) {
    formatted = formatted
      .replace(/(\.\d*[1-9])0*$/, '$1') // 123.1200 -> 123.12
      .replace(/\.0*$/, '') // 123.00 -> 123
      .replace(/^0.0+$/, '0'); // 0.00 -> 0
  }
  return formatted;
};

/**
 * Returns formatted number like:
 *   1234 -> 1234
 *   12345 -> 12.3k
 *   1234556 -> 1.2m
 * @param _num {String || Number}
 * @param decimals {Number}
 * @param removeTrailingZeroes {Boolean}
 * @param forceFullNumber {Boolean}
 * @returns {String}
 */
export const nFormatter = (_num, decimals = 2, removeTrailingZeroes = false, forceFullNumber = false) => {
  try {
    if (!Number.isFinite(parseFloat(_num))) return '0';
    const sign = parseFloat(_num) < 0 ? '-' : '';
    const num = Math.abs(parseFloat(_num));
    const _decimals = (num >= 10000 && !forceFullNumber) ? 0 : decimals;
    if (num < 1000000 || forceFullNumber) return numberWithCommas(_num, _decimals, removeTrailingZeroes);
    const si = [
      { value: 1, symbol: '', decimals },
      { value: 1E3, symbol: 'k', decimals: 0 },
      { value: 1E6, symbol: 'M', decimals: 1 },
      { value: 1E9, symbol: 'B', decimals: 1 },
      { value: 1E12, symbol: 'T', decimals: 1 },
      { value: 1E15, symbol: 'P', decimals: 1 },
      { value: 1E18, symbol: 'E', decimals: 1 },
    ];
    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }
    // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/; // remove trailing zeroes
    return sign + Dec(num / si[i].value).toFixed(si[i].decimals) + si[i].symbol;
  } catch (e) {
    return _num.toString();
  }
};

/**
 * formatNumber but returns ∞ instead of 0 for Infinity
 *
 * @param _num
 * @param params
 * @returns {String|string}
 */
export const formatNumberWithInf = (_num, ...params) => {
  if (!Number.isFinite(parseFloat(_num))) return '∞';
  return nFormatter(_num, ...params);
};

export const round = (num, decimals = 8) => Dec(num || 0).toDecimalPlaces(decimals, Dec.ROUND_HALF_EVEN).toString();

export const pickHex = (color1, color2, weight) => {
  const w = (weight * 2) - 1;
  const w1 = (w + 1) / 2;
  const w2 = 1 - w1;
  return [Math.round(color1[0] * w1 + color2[0] * w2), Math.round(color1[1] * w1 + color2[1] * w2), Math.round(color1[2] * w1 + color2[2] * w2)]; // eslint-disable-line
};

export const getRainbowSliderValColor = (val, gradients, sliderWidth) => {
  let colorRange = [];

  gradients.forEach((value, index) => {
    if (val <= value[0] && (colorRange.length === 0)) {
      colorRange = [index - 1, index];
    }
  });

  // Get the two closest colors
  const firstColor = gradients[colorRange[0]][1];
  const secondColor = gradients[colorRange[1]][1];

  // Calculate ratio between the two closest colors
  const firstColorX = sliderWidth * (gradients[colorRange[0]][0] / 100);
  const secondColorX = (sliderWidth * (gradients[colorRange[1]][0] / 100)) - firstColorX;
  const sliderX = (sliderWidth * (val / 100)) - firstColorX;
  const ratio = sliderX / secondColorX;

  // Get the color with pickHex(thx, less.js's mix function!)
  return pickHex(secondColor, firstColor, ratio);
};

export const getColorForRatio = (_ratio, _liqRatio) => {
  let ratio = _ratio;
  let liqRatio = _liqRatio;
  if (Number.isNaN(ratio)) ratio = 0;
  if (Number.isNaN(liqRatio)) liqRatio = 0;
  if (parseFloat(ratio) === 0) ratio = Infinity;
  if (ratio < liqRatio) ratio = liqRatio;
  // if (_ratio > 450) ratio = 250;

  let val = 1 + ((ratio - liqRatio) / (100 / 99));

  if (val > 100) val = 100;

  const RATIO_COLORS = [
    [0, [235, 87, 87]],
    [49, [242, 153, 74]],
    [100, [55, 176, 110]],
  ];

  const rbgArray = getRainbowSliderValColor(val, RATIO_COLORS, 250);
  return `rgb(${rbgArray.join(',')})`;
};

export const ratioToStatus = (_ratio) => {
  const ratio = parseFloat(_ratio);
  if (ratio <= 1.7) return 'Risky';
  if (ratio <= 2.5) return 'Moderate';
  return 'Safe';
};

export const shortAddress = (address) => {
  if (!address) return '';
  return `${address.substr(0, 6)}...${address.substr(address.length - 4)}`.toLowerCase();
};

/**
 * Compares two numbers up to N decimals
 * @param a
 * @param b
 * @param decimals
 * @returns {boolean}
 */
export const isEqual = (a, b, decimals = 1) => Dec(a).toDecimalPlaces(decimals).eq(Dec(b).toDecimalPlaces(decimals));

/**
 * Parse timestamp to readable date
 *
 * @param timestamp
 * @returns {string}
 */
export const parseTimestamp = (timestamp) => {
  const a = new Date(timestamp);

  const year = a.getFullYear();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();

  return `${month} ${date}. ${year}, ${hour}:${min}`;
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const timestampToTime = (_timestamp) => {
  const timestamp = parseInt(_timestamp, 10) < 9999999999
    ? (parseInt(_timestamp, 10) * 1000)
    : parseInt(_timestamp, 10);
  const dateString = (new Date(timestamp)).toTimeString();
  return `${dateString.substr(0, 5)} ${dateString.substr(9, 8)}`;
};

export const getRatioForPrice = (cdp, price) => nFormatter(((cdp.collateral * price) / cdp.debt) * 100);

export const blockToDate = (block) => {
  if (block > 11570000) {
    const blockDiff = block - 11570000;
    return new Date(1609521061000 + (blockDiff * 13399));
  }
  if (block > 9200000) {
    const blockDiff = block - 9200000;
    return new Date(1577950249000 + (blockDiff * 13321));
  }
  if (block > 9100000) {
    const blockDiff = block - 9100000;
    return new Date(1576236100000 + (blockDiff * 17141.49));
  }
  const blockDiff = block - 9100000;
  return new Date(1576236100000 + (blockDiff * 15052.27));
};

export const dateToBlock = (date) => {
  if (date.valueOf() > 1609521061000) {
    const dateDiff = date - 1609521061000;
    return Math.round(11570000 + (dateDiff / 13399));
  }
  if (date.valueOf() > 1577950249000) {
    const dateDiff = date - 1577950249000;
    return Math.round(9200000 + (dateDiff / 13321));
  }
  if (date.valueOf() > 1576236100000) {
    const dateDiff = date - 1576236100000;
    return Math.round(9100000 + (dateDiff / 17141.49));
  }

  const dateDiff = date - 1576236100000;
  return Math.round(9100000 + (dateDiff / 15052.27));
};

export const defined = (variable) => typeof variable !== 'undefined';

export const assetOrIlk = (asset, ilk) => {
  if (asset === 'ETH') {
    return ilk;
  }
  return asset;
};
