import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate';
import { Link } from 'react-router-dom';
import {
  getColorForRatio, nFormatter, parseTimestamp, shortAddress,
} from '../../services/utils';
import Arrow from '../Decorative/Arrow';
import AddressCsvExport from '../CSVExport/AddressCsvExport';
import CdpCsvExport from '../CSVExport/CdpCsvExport';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';
import Chevron from '../Decorative/Chevron';
import AutomationShield from '../Decorative/AutomationShield/AutomationShield';

class HistoryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      cdps, txs, singleCdp, order, onOrderChange,
    } = this.props;
    return (
      <div className="styled-table-wrapper">
        <table style={{ minWidth: singleCdp ? '900px' : '1000px' }}>
          <thead>
            <tr>
              {!singleCdp && (<th>ID</th>)}
              <th className="left">Activity</th>
              <th />
              <th>Collateral Change</th>
              <th>Debt Change</th>
              <th>Ratio</th>
              <th
                className="th_with_arrow active"
                onClick={() => {
                  onOrderChange(order === 'DESC'
                    ? 'ASC'
                    : 'DESC');
                }}
              >
                <div>
                  Date
                  <Chevron
                    orientation={order === 'DESC' ? 'down' : 'up'}
                    color="white"
                    size={25}
                  />
                </div>
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {
              txs.map?.((action) => {
                const cdp = singleCdp
                  ? cdps[0]
                  : cdps.find(({ cdpId }) => cdpId === action.cdpId);
                return (
                  <tr
                    key={`${action.timestamp}${action.actionType}${action.cdpId}`}
                    onClick={() => this.props.history.push(`/tx/${action.txHash}`)}
                  >
                    {!singleCdp && (<td>{action.cdpId}</td>)}
                    <td className="left">{t(`maker_actions.${action.actionType}`)}{action.actionInfo && ` (${action.actionInfo})`}</td>
                    <td>{action.automated && <TooltipWrapper title="Automated action" useText><AutomationShield active /></TooltipWrapper>}</td>
                    {
                      action.actionType === 'transfer'
                        ? (
                          <>
                            <td colSpan={3} className="center">
                              {shortAddress(action.from)}
                              {' '}<Arrow orientation="right" size={12} color="white" />{' '}
                              {shortAddress(action.to)}
                            </td>
                          </>
                        )
                        : (
                          <>
                            <td className={parseFloat(action.collChange) ? '' : 'empty'}>
                              <TooltipWrapper title={action.collChange}>
                                {nFormatter(action.collChange)} {cdp.asset}
                              </TooltipWrapper>
                            </td>
                            <td className={parseFloat(action.debtChange) ? '' : 'empty'}>
                              <TooltipWrapper title={action.debtChange}>
                                {nFormatter(action.debtChange)} DAI
                              </TooltipWrapper>
                            </td>
                            <td>
                              <span style={{ color: getColorForRatio(action.before.ratio * 100, cdp.liqRatio * 100) }}>
                                {nFormatter(action.before.ratio * 100)}%
                              </span>
                              {' '}<Arrow orientation="right" size={12} color="white" />{' '}
                              <span style={{ color: getColorForRatio(action.after.ratio * 100, cdp.liqRatio * 100) }}>
                                {nFormatter(action.after.ratio * 100)}%
                              </span>
                            </td>
                          </>
                        )
                    }
                    <td>{parseTimestamp(action.timestamp)}</td>
                    <td><Link to={`/tx/${action.txHash}`} onClick={(e) => e.stopPropagation()}><Arrow orientation="right" /></Link></td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {singleCdp ? <CdpCsvExport /> : <AddressCsvExport />}
      </div>
    );
  }
}


HistoryTable.propTypes = {
  cdps: PropTypes.array.isRequired,
  txs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  singleCdp: PropTypes.bool,
  order: PropTypes.string.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

HistoryTable.defaultProps = {
  singleCdp: false,
};

export default HistoryTable;
