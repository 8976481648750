
export const getCdpCsv = (actions) => `
date,action,txHash,collBefore,collAfter,collChange,debtBefore,debtAfter,debtChange,ratioBefore,ratioAfter,makerPrice
${actions.map((a) => {
    if (a.before) {
      return `
      ${a.timestamp},
      ${a.actionType},
      ${a.txHash},
      ${a.before.collateral},
      ${a.after.collateral},
      ${a.collChange},
      ${a.before.debt},
      ${a.after.debt},
      ${a.debtChange},
      ${a.before.ratio},
      ${a.after.ratio},
      ${a.price}
      `;
    }
    return `${a.timestamp},${a.actionType},${a.txHash},,,,,,,,,`;
  }).join('\n')}
`;

export const getAddressCsv = (actions) => `
date,cdpId,action,txHash,collBefore,collAfter,collChange,debtBefore,debtAfter,debtChange,ratioBefore,ratioAfter,makerPrice
${actions.map((a) => `
${a.timestamp},
${a.cdpId},
${a.actionType},
${a.txHash},
${a.before.collateral},
${a.after.collateral},
${a.collChange},
${a.before.debt},
${a.after.debt},
${a.debtChange},
${a.before.ratio},
${a.after.ratio},
${a.price}
`).join('\n')}
`;

export const parseCreationTransfer = (transferAction) => {
  const cdpCreationContracts = {
    '0xc73e0383f3aff3215e6f04b0331d58cecf0ab849': 'SAI Migration',
    '0x6d0984e80a86f26c0dd564ca0cf74a8e9da03305': 'DeFi Saver',
    '0xa483cfe6403949bf38c74f8c340651fb02246d21': 'DeFi Saver',
    '0x4c14ed0b3cac97939053be31150bdbb6f1ddbca2': 'yleverage.finance',
    '0x9fd2f710ad43d8b0a2a9d1ebd3fbbcadd5eec44f': 'Furucombo',
    '0x050cd4835b08babfc6ff92187971b3253e92640e': 'yleverage.finance',
    '0xa47ac1dd1cf8513b2eb5ca8bad5b8da93492bf02': 'Furucombo',
    '0xa5c1d410ba1a2839591a9672cc4e0e0281b23b1c': 'Furucombo',
  };
  return cdpCreationContracts[transferAction.from];
};
