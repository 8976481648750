import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, nFormatter } from '../../services/utils';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';

class CdpStateItem extends Component {
  constructor(props) {
    super();
  }

  render() {
    const {
      label,
      beforeCDP,
      afterCDP,
      valueSlug,
      prefix,
      suffix,
      after,
    } = this.props;

    let afterCDPProp = afterCDP[valueSlug];
    let beforeCDPProp = beforeCDP[valueSlug];
    if (Number.isNaN(+afterCDPProp)) {
      afterCDPProp = 0;
    }
    if (Number.isNaN(+beforeCDPProp)) {
      beforeCDPProp = 0;
    }
    const _value = (after ? afterCDPProp : beforeCDPProp);
    let value = _value;
    let change = afterCDPProp - beforeCDPProp;
    let changed = !isEqual(change, 0);
    if (valueSlug === 'collateral') {
      changed = !isEqual(change, 0, 2);
    }
    if (valueSlug === 'ratio') {
      change = (100 * afterCDPProp) - (100 * beforeCDPProp);
      changed = !isEqual(change, 0, 2);
      value = 100 * _value;
    }
    const flipChangeColor = (valueSlug === 'debt' || valueSlug === 'liqPrice');
    // eslint-disable-next-line no-nested-ternary
    const color = flipChangeColor
      ? (change > 0 ? 'red' : 'green')
      : (change > 0 ? 'green' : 'red');
    return (
      <div className={`row ${after ? 'after' : 'before'} ${changed ? 'changed' : ''}`}>
        <div className="label">{label}</div>
        <div className="value">
          <TooltipWrapper title={value}>
            {prefix}
            {nFormatter(value, 2)}
            {suffix}
          </TooltipWrapper>
        </div>
        {
          after && changed && (
            <div className={`value-addon ${color}`}>
              <TooltipWrapper title={change}>
                {change > 0 ? '+' : '-'}
                {prefix}
                {nFormatter(Math.abs(change), 2)}
                {suffix}
              </TooltipWrapper>
            </div>
          )
        }
      </div>
    );
  }
}

CdpStateItem.defaultProps = {
  prefix: '',
  suffix: '',
  after: false,
};

CdpStateItem.propTypes = {
  label: PropTypes.string.isRequired,
  beforeCDP: PropTypes.object.isRequired,
  afterCDP: PropTypes.object.isRequired,
  valueSlug: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  after: PropTypes.bool,
};

export default CdpStateItem;
