const randomNormalDist = () => {
  let u = 0;
  let v = 0;
  while (u === 0) u = Math.random();
  while (v === 0) v = Math.random();
  return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
};

export const generateRandomNormalDistribution = (_N) => {
  const arr = [];
  let N = _N;
  while (N--) {
    arr.push(randomNormalDist());
  }
  return arr;
};

// same as numpy linspace
export const linspace = (_start, end, _amount) => {
  let start = _start;
  let amount = _amount;
  const inc = (end - start) / (amount - 1);
  const arr = [];
  while (amount--) {
    arr.push(start);
    start += inc;
  }
  return arr;
};

// same as numpy cumsum
export const cumsum = (arr) => {
  let cur = 0;
  return arr.map((val) => {
    const rval = val + cur;
    cur = rval;
    return rval;
  });
};

// same as numpy exp
export const exp = (arr) => arr.map(Math.exp);

export const mulArrBy = (arr, mulBy) => arr.map((val) => val * mulBy);

export const addArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    throw new Error('Arrays of different length');
  }
  return arr1.map((val, i) => val + arr2[i]);
};
