import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nFormatter, numberWithCommas } from '../../../services/utils';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';

import './MakerManageProfitCalc.scss';

const Value = ({ value, tooltip }) => (
  <TooltipWrapper title={tooltip || `$${numberWithCommas(value.toFixed(2))}`}>
    <span className="value">{value < 0 && '-'}${ nFormatter(Math.abs(value), 2) }</span>
  </TooltipWrapper>
);
Value.defaultProps = {
  tooltip: '',
};
Value.propTypes = {
  value: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
};

class MakerManageProfitCalc extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { cdp } = this.props;
    return (
      <div className="maker-manage-profit-calc-wrapper">
        <div className="flex">
          <div className="labels">
            <div className="row label"><TooltipWrapper title="Total collateral added + DAI paid back"><i className="icon icon-Info-circle" /> Value Invested: </TooltipWrapper></div>
            <div className="row label"><TooltipWrapper title="Total DAI generated + collateral withdrawn"><i className="icon icon-Info-circle" /> Value Withdrawn: </TooltipWrapper></div>
            <div className="row label"><TooltipWrapper title="Current collateral value - debt"><i className="icon icon-Info-circle" /> CDP Balance: </TooltipWrapper></div>
            <div className="row label"><TooltipWrapper title="CDP balance + value withdrawn - value invested"><i className="icon icon-Info-circle" /> Lifetime Profit: </TooltipWrapper></div>
          </div>
          <div className="values">
            <div className="row"><Value value={cdp.valueImpored} /></div>
            <div className="row"><Value value={cdp.valueExpored} /></div>
            <div className="row"><Value value={cdp.cdpBalance} tooltip={`\n$${numberWithCommas(cdp.cdpBalance.toFixed(2))}\n${numberWithCommas((cdp.cdpBalance / cdp.price).toFixed(2))} ${cdp.asset}`} /></div>
            <div className={`row ${(cdp.profit < 0 ? 'falling' : 'rising')}`}><Value value={cdp.profit} /></div>
          </div>
        </div>
      </div>
    );
  }
}

MakerManageProfitCalc.propTypes = {
  cdp: PropTypes.object.isRequired,
};

MakerManageProfitCalc.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
});


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MakerManageProfitCalc);
