import React from 'react';
import { Redirect } from 'react-router-dom';

const Search = () => {
  const searchQuery = document.location.search.substr(3);
  let path = '/';
  if (searchQuery.length <= 6 && searchQuery.match(/^\d+$/)) {
    path = `/cdp/${searchQuery}`; // CDP query
  } else if (searchQuery.length === 42 && searchQuery.substr(0, 2).toLowerCase() === '0x') {
    path = `/address/${searchQuery.toLowerCase()}`; // address query
  } else if (searchQuery.length === 66 && searchQuery.substr(0, 2).toLowerCase() === '0x') {
    path = `/tx/${searchQuery.toLowerCase()}`; // tx query
  } else if (searchQuery.match(/^(\w+\.)+\w+$/)) {
    path = `/address/${searchQuery.toLowerCase()}`; // ENS query
  }

  return (<Redirect to={path} />);
};

export default Search;
