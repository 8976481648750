import React from 'react';
import PropTypes from 'prop-types';
import t from 'translate';

import './Loader.scss';

const LoaderIcon = ({ color, stroke, mini }) => (
  <span className={`loader ${mini ? 'mini' : ''}`}>
    <svg className="circular" viewBox="25 25 50 50">
      <circle stroke={color} className="path" cx="50" cy="50" r="20" fill="none" strokeWidth={stroke} strokeMiterlimit="10" />
    </svg>
  </span>
);

LoaderIcon.defaultProps = {
  color: '#5BB4A7',
  stroke: 2,
  mini: false,
};

LoaderIcon.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.number,
  mini: PropTypes.bool,
};

export default LoaderIcon;
