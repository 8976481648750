/* eslint-disable */
import React from 'react';

const Chevron = ({ size = 25, color = '#61717E', orientation = 'down' }) => (
  <svg
    className="decorative-arrow"
    style={{ transform: `rotate(${{ down: 0, left: 90, up: 180, right: 270 }[orientation]}deg)` }}
    width={size}
    height={size}
    viewBox="0 0 20 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 14L10 20L4 14" stroke={color} />
  </svg>
);
export default Chevron;
