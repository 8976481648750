import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { getAssetInfo } from '@defisaver/tokens';
import Select from 'react-select';
import { DfsMultiselect } from '@defisaver/components';
import { getColorForRatio, ratioToStatus, nFormatter } from '../../../services/utils';
import Arrow from '../../Decorative/Arrow';
import ErrorFallback from '../../ErrorFallback/ErrorFallback';
import './TopCdps.scss';
import AutomationShield from '../../Decorative/AutomationShield/AutomationShield';
import { clearSearchData, getTopCdps } from '../../../actions/apiActions';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';
import Chevron from '../../Decorative/Chevron';
import Paginator from '../../Paginator/Paginator';

const TopCdps = (props) => {
  const {
    globalInfo,
    globalInfoLoading,
  } = props;
  useEffect(() => {
    props.clearSearchData();
  }, []);

  const onOrderChange = (order, sortBy) => {
    props.getTopCdps(1, 20, order, sortBy, props.topCdps.filters);
  };

  const toggleSorting = (column) => {
    const order = props.topCdps.sortBy === column ? props.topCdps.order === 'DESC' ? 'ASC' : 'DESC' : 'DESC';
    onOrderChange(order, column);
  };

  const onPaginationChange = (newPageNumber) => {
    props.getTopCdps(newPageNumber, 20, props.topCdps.order, props.topCdps.sortBy, props.topCdps.filters);
  };

  const onFilterChange = (filters) => {
    console.log(filters);
    props.getTopCdps(1, 20, props.topCdps.order, props.topCdps.sortBy, filters.length ? filters.map(filter => filter.value) : []);
  };

  return (
    <div className="top-cdps-wrapper">
      <div className="top-cdps-header">
        <h1>Top 20 Vaults</h1>

        <div className="multiselect">
          <div className="label">Collateral types:</div>
          <DfsMultiselect
            loading={globalInfoLoading}
            options={
              globalInfo.tokenData
                ? Object.values(globalInfo.tokenData).map((item) => ({
                  label: item.ilk,
                  value: item.ilk,
                  asset: item.asset,
                  icon: getAssetInfo(item.asset)?.icon(),
                }))
                : []
            }
            value={
              globalInfo.tokenData
                ? Object.values(globalInfo.tokenData).filter((item) => props.topCdps.filters.includes(item.ilk))
                  .map((item) => ({
                    label: item.ilk,
                    value: item.ilk,
                    asset: item.asset,
                    icon: getAssetInfo(item.asset)?.icon(),
                  }))
                : []
            }
            bordered
            onValueChange={onFilterChange}
            style={{ minWidth: '250px', backgroundColor: '#202931' }}
            selectedCutOff={7}
          />
        </div>
      </div>

      <div className="styled-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th
                className={`th_with_arrow ${
                  props.topCdps.sortBy === 'debt' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleSorting('debt');
                }}
              >
                <div>
                  Debt
                  {props.topCdps.sortBy === 'debt' ? (
                    <Chevron
                      orientation={
                        props.topCdps.order === 'DESC' ? 'down' : 'up'
                      }
                      color="white"
                      size={25}
                    />
                  ) : (
                    <div className="multiple-arrows">
                      <Chevron orientation="up" size={25} />
                      <Chevron orientation="down" size={25} />
                    </div>
                  )}
                </div>
              </th>
              <th
                className={`th_with_arrow ${
                  props.topCdps.sortBy === 'collateral' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleSorting('collateral');
                }}
              >
                <div>
                  Collateral
                  {props.topCdps.sortBy === 'collateral' ? (
                    <Chevron
                      orientation={
                        props.topCdps.order === 'DESC' ? 'down' : 'up'
                      }
                      color="white"
                      size={25}
                    />
                  ) : (
                    <div className="multiple-arrows">
                      <Chevron orientation="up" size={25} />
                      <Chevron orientation="down" size={25} />
                    </div>
                  )}
                </div>
              </th>
              <th
                className={`th_with_arrow ${
                  props.topCdps.sortBy === 'ratio' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleSorting('ratio');
                }}
              >
                <div>
                  Ratio
                  {props.topCdps.sortBy === 'ratio' ? (
                    <Chevron
                      orientation={
                        props.topCdps.order === 'DESC' ? 'down' : 'up'
                      }
                      color="white"
                      size={25}
                    />
                  ) : (
                    <div className="multiple-arrows">
                      <Chevron orientation="up" size={25} />
                      <Chevron orientation="down" size={25} />
                    </div>
                  )}
                </div>
              </th>
              <th>Status</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {props.topCdps.data.map((cdp) => (
              <tr
                key={cdp.cdpId}
                onClick={() => props.history.push(`/cdp/${cdp.cdpId}`)}
              >
                <td>
                  {getAssetInfo(cdp.asset)?.icon()} {cdp.cdpId}
                </td>
                <td>
                  <TooltipWrapper title={cdp.debt}>
                    {nFormatter(cdp.debt)} DAI
                  </TooltipWrapper>
                </td>
                <td>
                  <TooltipWrapper title={cdp.collateral}>
                    {nFormatter(cdp.collateral)} {cdp.asset}
                  </TooltipWrapper>
                </td>
                <td
                  style={{
                    color: getColorForRatio(
                      cdp.ratio * 100,
                      cdp.liqRatio * 100,
                    ),
                  }}
                >
                  {cdp.subscribedToAutomation && (
                    <TooltipWrapper title="Protected by DeFi Saver Automation">
                      <AutomationShield active />
                    </TooltipWrapper>
                  )}
                  {nFormatter(cdp.ratio * 100)}%
                </td>
                <td>{ratioToStatus(cdp.ratio)}</td>
                <td>
                  <Arrow orientation="right" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Paginator
        currentPage={props.topCdps.pageNumber}
        totalCount={props.topCdps.total}
        pageSize={20}
        onPageChange={onPaginationChange}
      />
    </div>
  );
};
TopCdps.propTypes = {
  clearSearchData: PropTypes.func.isRequired,
  topCdps: PropTypes.object.isRequired,
  // topCdpsLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getTopCdps: PropTypes.func.isRequired,
  globalInfo: PropTypes.object.isRequired,
  globalInfoLoading: PropTypes.bool.isRequired,
};

TopCdps.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  topCdps: general.topCdps,
  globalInfo: general.globalInfo,
  globalInfoLoading: general.globalInfoLoading,
  topCdpsLoading: general.topCdpsLoading,
});

const mapDispatchToProps = {
  clearSearchData,
  getTopCdps,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(withRouter(TopCdps)), ErrorFallback);
