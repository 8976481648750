import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { shortAddress } from '../../../services/utils';

import './MakerManageHeader.scss';

class MakerManageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { cdp } = this.props;
    return (
      <div className="maker-manage-header text-extra-large">
        <div>
          <span>Owner: </span>
          <Link to={`/address/${cdp.userAddr}`}>
            {shortAddress(cdp.userAddr)}
          </Link>
        </div>
        <div>
          <a
            href={`https://app.defisaver.com/makerdao/manage/${cdp.cdpId}?trackAddress=${cdp.userAddr}&chainId=1`}
            target="_blank"
            rel="noopener noreferrer"
            className="button green"
          >
            Open Dashboard
          </a>
        </div>
      </div>
    );
  }
}

MakerManageHeader.propTypes = {
  cdp: PropTypes.object.isRequired,
};

MakerManageHeader.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MakerManageHeader);
