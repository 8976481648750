import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet/es/Helmet';
import { parseUrl } from '../../actions/apiActions';

class Title extends Component {
  constructor(props) {
    super();
  }

  render() {
    const url = parseUrl();
    let title = 'DeFi Explore';
    if (url.type === 'cdp') title = `DeFi Explore | MCD #${url.cdpId}`;
    if (url.type === 'address') title = `DeFi Explore | Address ${url.address}`;
    if (url.type === 'tx') title = `DeFi Explore | Tx ${url.txHash}`;

    return (
      <Helmet><title>{title}</title></Helmet>
    );
  }
}

export default withRouter(Title);
