import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowCaret from '../../Decorative/ArrowCaret';

const Expandable = ({ title, children, initialExpandedState }) => {
  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  return (
    <div className="expandable-outer-wrapper">
      <div className="Flex SpaceBetween expandable-toggle-wrapper" onClick={() => setIsExpanded(prevState => (!prevState))} role="button" tabIndex={-1}>
        <span>{title}</span>
        <ArrowCaret to={isExpanded ? 'up' : 'down'} />
      </div>
      { isExpanded && children }
    </div>
  );
};
Expandable.defaultProps = {
  initialExpandedState: false,
};
Expandable.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  initialExpandedState: PropTypes.bool,

};
export default Expandable;


