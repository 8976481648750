/* eslint-disable react/no-unused-state */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import ErrorBoundary, { withErrorBoundary } from 'react-error-boundary';
import { DatePicker } from 'react-widgets';
import { ilkToAsset } from '@defisaver/tokens';
import { getSimulationData } from '../../actions/apiActions';
import '../Stats/ActionsOverTimeGraph/graphSettings';
import './CdpSimulation.scss';
import {
  calculate, parseData, getLiqRatio, getLastBlockAndPrice, generateFuturePrices, ilkLiquidationRatios,
} from '../../services/simulationService';
import AutomationHistory from './AutomationHistory/AutomationHistory';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import { blockToDate, dateToBlock } from '../../services/utils';
import CdpSimulatedOutput from './CdpSimulatedOutput/CdpSimulatedOutput';
import LoaderIcon from '../Decorative/Loader/LoaderIcon';
import Expandable from './Expand/Expandable';
import Slider from './Slider/Slider';
import TooltipWrapper from '../Decorative/TooltipWrapper/TooltipWrapper';

const _chartOptions = {
  chart: {
    height: 500,
  },
  credits: { enabled: false },
  legend: { enabled: true },
  series: [],
  yAxis: [
    {
      title: { text: 'Price' },
      gridLineWidth: 0,
    },
    {
      title: { text: 'CDP Ratio' },
      min: 145,
      plotLines: [],
      gridLineWidth: 0,
    },
    {
      title: { text: 'CDP Profit' },
      gridLineWidth: 0,
      plotLines: [{
        dashStyle: 'dot',
        value: 0,
        zIndex: 0,
        color: '#F2C94C',
      }],
    },
    {
      top: '72px',
    },
  ],
  xAxis: {},
};

const Input = ({
  name, label, type, onChange, state, secondaryLabel, description, ...props
}) => (
  <div className="input-wrapper">
    <label htmlFor={name}>
      {
        description
          ? <TooltipWrapper title={description}><i className="icon icon-Info-circle" /> {label}</TooltipWrapper>
          : label
      }
    </label>
    <input
      type={type}
      name={name}
      id={name}
      value={state[name]}
      onChange={onChange}
      autoComplete="off"
      // className={`${secondaryLabel && 'has-'}`}
      {...props}
    />
    {
      secondaryLabel
        ? <label htmlFor={name} className="second-label">{secondaryLabel}</label>
        : <label htmlFor={name} className="label-replacement" />
    }
  </div>
);
Input.defaultProps = {
  type: 'number',
  description: '',
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  secondaryLabel: PropTypes.string,
  description: PropTypes.string,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const CdpSimulation = ({ getSimulationData }) => {
  const [cdp, setCdp] = useState({
    debt: 0,
    collateral: 10,
    optimalRatioBoost: 195,
    trailingStop: 20,
    maxBoost: 215,
    optimalRatioRepay: 185,
    minRepay: 165,
    liqRatio: 1.45,
    block: dateToBlock(new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)).setDate((new Date()).getDate())),
    startDate: new Date(new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)).setDate((new Date()).getDate())),
    endDate: new Date(),
    volatility: 20,
    drift: 100,
    ilk: 'ETH-A',
    asset: 'ETH',
    gasPrice: 30,
  });
  const [simulation, setSimulation] = useState({
    actions: [],
    loading: true,

  });
  const [pricesData, setPricesData] = useState({});
  const [chartOptions, setChartOptions] = useState(null);

  const cdpSimulationData = useSelector((state) => state.general.cdpSimulationData);
  const cdpSimulationDataLoading = useSelector((state) => state.general.cdpSimulationDataLoading);

  const loading = simulation.loading || cdpSimulationDataLoading;

  useEffect(() => {
    getSimulationData(cdp.ilk);
  }, [cdp.ilk]);

  const initializeSimulationData = (e) => {
    if (e) e.preventDefault();
    if (cdpSimulationData[cdp.ilk]?.makerPrices?.length) {
      const pricesData = {
        makerPrices: [...cdpSimulationData[cdp.ilk].makerPrices],
        exchPrices: [...cdpSimulationData[cdp.ilk].exchPrices],
        rates: [...cdpSimulationData[cdp.ilk].rates],
        extraPrices: [],
      };
      const futureTime = cdp.endDate.getTime() - Date.now();
      if (futureTime > 0) {
        const { lastBlock, lastPrice } = getLastBlockAndPrice(pricesData.makerPrices, pricesData.exchPrices, pricesData.extraPrices);
        const futurePrices = generateFuturePrices(futureTime, (cdp.drift + 100) / 100, cdp.volatility / 100, lastPrice, lastBlock);
        pricesData.makerPrices = [...pricesData.makerPrices, ...futurePrices];
        pricesData.exchPrices = [...pricesData.exchPrices, ...futurePrices];
      }
      setPricesData(pricesData);
    }
  };
  useEffect(() => {
    initializeSimulationData();
  }, [cdpSimulationData, cdp.ilk]);

  const updateSimulation = async () => {
    const ilkAvailableSinceBlock = Math.max(
      pricesData.makerPrices[0][0],
      pricesData.exchPrices[0][0],
      pricesData.rates[0][0],
    );
    if (cdp.block < ilkAvailableSinceBlock) {
      cdp.startDate = blockToDate(ilkAvailableSinceBlock);
      cdp.block = ilkAvailableSinceBlock;
      setCdp(cdp);
    }
    const ethPrices = cdpSimulationData['ETH-A'].exchPrices;
    setSimulation((prevState) => ({ ...prevState, loading: true }));
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((res) => setTimeout(res)); // wait for React to render component with loader
    const makerPrices = [...pricesData.makerPrices, ...pricesData.extraPrices];
    const exchPrices = [...pricesData.exchPrices, ...pricesData.extraPrices];
    const simulation = await calculate(cdp, makerPrices, exchPrices, pricesData.rates, ethPrices);
    const chartOptions = parseData(_chartOptions, cdp, simulation, pricesData.rates, exchPrices, makerPrices);
    setChartOptions(chartOptions);
    setSimulation({ ...simulation, loading: false });
  };

  useEffect(() => {
    if (pricesData?.makerPrices?.length) {
      updateSimulation();
    }
  }, [pricesData.makerPrices, pricesData.extraPrices]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    let toUpdate = { [name]: value };
    if (name === 'ilk') {
      toUpdate = {
        ...toUpdate,
        asset: ilkToAsset(value),
        liqRatio: getLiqRatio(value),
        minRepay: (getLiqRatio(value) * 100) + 20,
        optimalRatioRepay: (getLiqRatio(value) * 100) + 40,
        optimalRatioBoost: (getLiqRatio(value) * 100) + 50,
        maxBoost: (getLiqRatio(value) * 100) + 70,
      };
    }
    setCdp((prevState) => ({
      ...prevState,
      ...toUpdate,
    }));
  };

  const handleDateInput = (date, endDate = false) => {
    if (endDate) {
      setCdp((prevState) => ({
        ...prevState,
        endDate: date,
      }));
    } else {
      setCdp((prevState) => ({
        ...prevState,
        startDate: date,
        block: dateToBlock(date),
      }));
    }
  };
  const handleAvgYearlyGrowthInput = (value) => {
    setCdp((prevState => ({ ...prevState, drift: value })));
  };
  const simulateBullRun = () => {
    const lastData = getLastBlockAndPrice(pricesData.makerPrices, pricesData.exchPrices, pricesData.extraPrices);
    const lastBlock = lastData.lastBlock;
    let lastPrice = lastData.lastPrice;
    const extraPricesNew = [];
    for (let i = 1; i < 7 * 24; i++) {
      lastPrice *= 1 + (0.005 * (Math.random() - 0.45));
      extraPricesNew.push([lastBlock + (240 * i), lastPrice]);
    }
    setPricesData((prevState => ({
      ...prevState,
      extraPrices: [...prevState.extraPrices, ...extraPricesNew],
    })));
  };

  const simulateBearRun = () => {
    const lastData = getLastBlockAndPrice(pricesData.makerPrices, pricesData.exchPrices, pricesData.extraPrices);
    const lastBlock = lastData.lastBlock;
    let lastPrice = lastData.lastPrice;
    const extraPricesNew = [];
    for (let i = 1; i < 7 * 24; i++) {
      lastPrice *= 1 + (0.005 * (Math.random() - 0.55));
      extraPricesNew.push([lastBlock + (240 * i), lastPrice]);
    }
    setPricesData((prevState => ({
      ...prevState,
      extraPrices: [...prevState.extraPrices, ...extraPricesNew],
    })));
  };

  return (
    <div className="cdp-simulation-wrapper">
      <div className="width-container">
        <div className="element OverflowWrapper">
          <h1>CDP Automation Simulation</h1>
          <div className="intro">
            The Simulation tools lets you see how different Automation
            configurations would perform in different market conditions. It
            considers Automation a tool for continuous leveraging throughout the
            simulated period, as well as the same parameters with an additional
            trailing stop configuration. The final result is compared to simply
            holding the initial amount. For some previous experimentation and
            thoughts, please see our{' '}
            <a
              href="https://medium.com/defi-saver/defi-saver-automation-performance-analysis-setting-up-for-maximum-profits-eb486b5c9ea6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Automation performance analysis post
            </a>
          </div>
          <form onSubmit={initializeSimulationData} className="settings-panel">
            <h2>Setup</h2>
            <div className="form-inner">
              <div className="flex lg12">
                <div className="flex lg6 md12">
                  <div className="input-wrapper">
                    <label htmlFor="ilk">Vault type</label>
                    <select
                      name="ilk"
                      id="ilk"
                      value={cdp.ilk}
                      onChange={handleInput}
                    >
                      {Object.keys(ilkLiquidationRatios).map((ilk) => (
                        <option value={ilk} key={ilk}>
                          {ilk}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex lg6 md12">
                  <Input
                    label="Amount to leverage"
                    name="collateral"
                    state={cdp}
                    onChange={handleInput}
                    required
                    secondaryLabel={cdp.asset}
                  />
                </div>
              </div>
              <div className="flex lg12">
                <div className="flex lg6 md12">
                  <div className="input-wrapper">
                    <label htmlFor="startDate">Start date</label>
                    <DatePicker
                      value={cdp.startDate}
                      onChange={(date) => {
                        handleDateInput(date, false);
                      }}
                      name="startDate"
                      id="startDate"
                      valueFormat={{ dateStyle: 'medium' }}
                      max={
                        new Date(cdp.endDate.getTime() - (24 * 60 * 60 * 1000))
                      }
                    />
                  </div>
                </div>
                <div className="flex lg6 md12">
                  <div className="input-wrapper">
                    <label htmlFor="startDate">End date</label>
                    <DatePicker
                      value={cdp.endDate}
                      onChange={(date) => {
                        handleDateInput(date, true);
                      }}
                      name="endDate"
                      id="endDate"
                      valueFormat={{ dateStyle: 'medium' }}
                      min={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg12">
                <Expandable title="Simulation Settings">
                  <div className="expandable-wrapper">
                    <h3>Leverage Management</h3>

                    <div className="flex lg12">
                      <div className="flex lg6 md12">
                        <div className="flex sm12">
                          <Input
                            label="Repay from"
                            secondaryLabel="%"
                            name="minRepay"
                            state={cdp}
                            onChange={handleInput}
                            min={(+cdp.liqRatio * 100) + 10}
                            required
                          />
                        </div>
                        <div className="flex sm12">
                          <Input
                            label="Repay to"
                            secondaryLabel="%"
                            name="optimalRatioRepay"
                            state={cdp}
                            onChange={handleInput}
                            min={+cdp.minRepay + 5}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex lg6 md12">
                        <div className="flex sm12">
                          <Input
                            label="Boost from"
                            secondaryLabel="%"
                            name="maxBoost"
                            state={cdp}
                            onChange={handleInput}
                            required
                          />
                        </div>
                        <div className="flex sm12">
                          <Input
                            label="Boost to"
                            secondaryLabel="%"
                            name="optimalRatioBoost"
                            state={cdp}
                            onChange={handleInput}
                            max={+cdp.maxBoost - 5}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <h3>Trailing stop</h3>

                    <div className="flex lg12">
                      <div className="flex lg6 md12">
                        <Input
                          label="Trailing stop"
                          secondaryLabel="%"
                          name="trailingStop"
                          state={cdp}
                          onChange={handleInput}
                          min={0}
                          required
                        />
                      </div>
                      <div />
                    </div>

                    <h3>Market Behavior</h3>

                    <div className="flex lg12">
                      <div className="flex lg6 md12">
                        <Input
                          label="Avg. gas price"
                          secondaryLabel="gw"
                          name="gasPrice"
                          state={cdp}
                          onChange={handleInput}
                          required
                        />
                      </div>
                      <div className="flex lg6 md12" />
                    </div>

                    <h3>Price Assumptions</h3>

                    <div className="flex lg12">
                      <div className="flex lg6 md12">
                        <Slider
                          min={-100}
                          max={300}
                          step={1}
                          defaultValue={cdp.drift}
                          value={cdp.drift}
                          onChange={handleAvgYearlyGrowthInput}
                        />
                      </div>
                      <div className="flex lg6 md12">
                        <Input
                          label="Volatility"
                          secondaryLabel="%"
                          name="volatility"
                          description="Volatility represents how drastically the asset's value changes over time. High volatility implies big swings in asset's price."
                          state={cdp}
                          onChange={handleInput}
                          min={0}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Expandable>
              </div>
            </div>

            <div className="Flex FlexEnd form-bottom-wrapper">
              {loading && (
                <div className="Flex">
                  <LoaderIcon mini /> Loading...
                </div>
              )}
              <button type="submit" className="button green">
                Simulate
              </button>
            </div>
          </form>

          <div className="future-price-settings-wrapper">
            <p>Additional future prices:</p>
            <div className="Flex FlexWrap">
              <button
                type="button"
                className="button mini"
                onClick={simulateBullRun}
              >
                Add 1-week Bull Run
              </button>
              <button
                type="button"
                className="button mini"
                onClick={simulateBearRun}
              >
                Add 1-week Bear Run
              </button>
            </div>
            <button
              type="button"
              className="button mini"
              onClick={() => setPricesData((prevState) => ({
                ...prevState,
                extraPrices: [],
              }))}
            >
              Reset
            </button>
          </div>

          {simulation?.finalCdp && (
            <>
              <h2>Outcome</h2>
              <CdpSimulatedOutput cdp={simulation?.finalCdp} />
              <Expandable title="Show graph">
                <div className="graph-wrapper">
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType="stockChart"
                      options={chartOptions}
                    />
                  </ErrorBoundary>
                </div>
              </Expandable>
            </>
          )}

          <AutomationHistory history={simulation.actions} cdp={cdp} />
        </div>
      </div>
    </div>
  );
};


CdpSimulation.propTypes = {
  getSimulationData: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
  getSimulationData,
};

export default withErrorBoundary(connect(undefined, mapDispatchToProps)(CdpSimulation), ErrorFallback);
