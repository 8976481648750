import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import './graphSettings';
import { getActionsOverTimeData, getEthPriceOverTime } from '../../../actions/apiActions';
import ErrorFallback from '../../ErrorFallback/ErrorFallback';
import { numberWithCommas } from '../../../services/utils';

import './ActionsOverTimeGraph.scss';

window.Highcharts = Highcharts;

const _chartOptions = {
  credits: { enabled: false },
  rangeSelector: {
    selected: 3,
  },
  legend: {
    enabled: true,
  },
  title: { text: '' },
  tooltip: { split: true },
  series: [],
  yAxis: [
    {
      title: {
        text: 'Actions',
      },
    },
    {
      title: {
        text: 'ETH Price',
      },
    },
  ],
};

class ActionsOverTimeGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataType: 'dataByDate',
    };
  }

  componentDidMount() {
    this.props.getActionsOverTimeData();
    this.props.getEthPriceOverTime();
  }

  render() {
    const { dataType } = this.state;
    const { actionsOverTimeLoading, actionsOverTime, ethPriceOverTime } = this.props;
    const chartOptions = { ..._chartOptions };
    // eslint-disable-next-line no-param-reassign
    chartOptions.yAxis.forEach((a) => { a.visible = window.innerWidth > 900; });
    if (actionsOverTime.actions) {
      const chartData = [];
      actionsOverTime.actions.forEach((action) => {
        chartData.push({
          type: 'column',
          name: action,
          id: `${dataType}-${action}`,
          data: [],
          xAxis: 0,
          yAxis: 0,
          zIndex: 0,
          visible: true,
          tooltip: {
            pointFormatter() {
              return `${this.series.name}: <b>${dataType === 'dataByDate' ? '' : '$'}${numberWithCommas(this.y, 0)}</b>`;
            },
          },
        });
      });
      Object.keys(actionsOverTime[dataType]).forEach((date) => {
        actionsOverTime[dataType][date].forEach((count, i) => chartData[i].data.push([new Date(date).valueOf(), Math.abs(count)]));
      });

      if (ethPriceOverTime.length) {
        chartData.push({
          type: 'line',
          name: 'ETH Price',
          id: `${dataType}-ETH Price`,
          data: ethPriceOverTime.map((a) => [new Date(a[0]).valueOf(), a[1]]),
          yAxis: 1,
          zIndex: 1,
          visible: true,
          tooltip: {
            pointFormatter() {
              return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
            },
          },
        });
      }

      chartOptions.colors = ['#37B06F', '#F55858', '#F2C94C', '#9B51E0', '#56CCF2', '#E4761B', '#37B06F'];
      chartOptions.series = chartData.reduce((seriesArray, currentSeries, i) => {
        const action = currentSeries.name;
        if (dataType === 'debtVolumeByDate') {
          if (action === 'Add Collateral' || action === 'Withdraw Collateral') return seriesArray;
        }
        if (dataType === 'collVolumeByDate') {
          if (action === 'Generate DAI' || action === 'Payback DAI') return seriesArray;
        }
        seriesArray.push({ ...currentSeries, color: chartOptions.colors[i] });
        return seriesArray;
      }, []);
      if (this.chart) {
        chartOptions.rangeSelector.selected = undefined;
      }
    }

    return (
      <div className="actions-over-time-graph-wrapper">
        <div className="Flex SpaceBetween graph-title-wrapper">
          <h1>Historical Data</h1>
          <div className="graph-type-switch-wrapper">
            <button
              type="button"
              className={`${dataType === 'dataByDate' ? 'active' : ''}`}
              onClick={() => this.setState({ dataType: 'dataByDate' })}
            >
              Number of Action
            </button>
            <button
              type="button"
              className={`${dataType === 'debtVolumeByDate' ? 'active' : ''}`}
              onClick={() => this.setState({ dataType: 'debtVolumeByDate' })}
            >
              DAI Volume
            </button>
            <button
              type="button"
              className={`${dataType === 'collVolumeByDate' ? 'active' : ''}`}
              onClick={() => this.setState({ dataType: 'collVolumeByDate' })}
            >
              Collateral Volume (USD)
            </button>
          </div>
        </div>
        <div className="graph-wrapper">
          {
            !actionsOverTimeLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                constructorType="stockChart"
                options={chartOptions}
                ref={(ref) => { this.chart = ref; }}
              />
            )
          }
          {
            actionsOverTimeLoading && (<p>Loading...</p>)
          }
        </div>
      </div>
    );
  }
}

ActionsOverTimeGraph.propTypes = {
  actionsOverTimeLoading: PropTypes.bool.isRequired,
  actionsOverTime: PropTypes.object.isRequired,
  ethPriceOverTime: PropTypes.array.isRequired,
  getActionsOverTimeData: PropTypes.func.isRequired,
  getEthPriceOverTime: PropTypes.func.isRequired,
};

ActionsOverTimeGraph.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  actionsOverTimeLoading: general.actionsOverTimeLoading,
  actionsOverTime: general.actionsOverTime,
  ethPriceOverTime: general.ethPriceOverTime,
});

const mapDispatchToProps = {
  getActionsOverTimeData,
  getEthPriceOverTime,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(ActionsOverTimeGraph), ErrorFallback);
