// Import translations first!
import './i18n/translations';

import React from 'react';
import ReactDOM from 'react-dom';
import { set as dfsTokensSetConfig } from '@defisaver/tokens';
import TokenIcon from './components/TokenIcon/TokenIcon';
import App from './components/App/App';
import store from './store';
import pkg from '../package.json';
// import './sentry';

dfsTokensSetConfig('iconFunc', TokenIcon);

console.log(`DeFi Explore v${pkg.version}`);

ReactDOM.render(<App store={store} />, document.getElementById('root'));
