import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';
import { nFormatter, numberWithCommas } from '../../../services/utils';
import Arrow from '../../Decorative/Arrow';
import ErrorFallback from '../../ErrorFallback/ErrorFallback';
import './MiniStatWrapper.scss';

const MiniStatWrapper = ({
  value, primaryLabel, secondaryLabel, loading, round,
}) => (
  <div className="mini-stat-wrapper">
    {
      !value && !loading && (<Arrow orientation="right" color="white" size={43} />)
    }
    {
      (value || loading) && (
        <h2>
          {
            round
              ? (
                <TooltipWrapper title={value || '...'}>
                  {loading ? '...' : nFormatter(value, 2, true)}
                </TooltipWrapper>
              )
              : numberWithCommas(value)
          }
        </h2>
      )
    }
    <p><b>{primaryLabel}</b></p>
    <p>{secondaryLabel}</p>
  </div>
);

MiniStatWrapper.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  primaryLabel: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  round: PropTypes.bool,
};

MiniStatWrapper.defaultProps = {
  value: '',
  loading: false,
  round: false,
};

export default withErrorBoundary(MiniStatWrapper, ErrorFallback);
