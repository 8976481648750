import Dec from 'decimal.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsBoost from 'highcharts/modules/boost';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import t from 'translate';
import { getSimulationData } from '../../actions/apiActions';
import { blockToDate, nFormatter } from '../../services/utils';
import graphPointRed from '../CdpSimulation/graph-action-point-red.svg';
import graphPoint from '../CdpSimulation/graph-action-point.svg';
import Loader from '../Decorative/Loader/Loader';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import '../Stats/ActionsOverTimeGraph/graphSettings';
import './CdpHistoryGraph.scss';

HighchartsBoost(Highcharts);

const _chartOptions = {
  chart: {
    height: 500,
    spacing: 15,
  },
  credits: { enabled: false },
  legend: { enabled: true },
  plotOptions: {
    series: {
      boostThreshold: 1,
      dataGrouping: {
        forced: true,
        units: [
          ['hour', [1]],
        ],
      },
    },
  },
  series: [],
  yAxis: [
    {
      title: { text: 'ETH Price' },
      gridLineWidth: 0,
      alignTicks: false,
      showEmpty: false,
    },
    {
      title: { text: 'CDP Ratio' },
      // min: 150,
      gridLineWidth: 0,
      alignTicks: false,
      showEmpty: false,
    },
    {
      title: { text: 'CDP Profit' },
      gridLineWidth: 0,
      plotLines: [{
        color: '#F2C94C',
        dashStyle: 'dot',
        value: 0,
        zIndex: 0,
      }],
      alignTicks: false,
      showEmpty: false,
    },
    {
      top: '77px',
    },
  ],
  xAxis: {},
};

class CdpHistoryGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: null,
      loading: true,
      noActions: false,
    };

    this.parseData = this.parseData.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.getSimulationData(this.props.cdp.ilk);
      if (this.props.cdpSimulationData?.makerPrices.length) this.parseData();
    }, 30);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.txs !== prevProps.txs) this.parseData();
    if (this.props.cdpSimulationData !== prevProps.cdpSimulationData) this.parseData();
  }

  cdpBalance(cdp, price) {
    return (cdp.collateral * price) - cdp.debt;
  }

  parseAction(e, liqHappened) {
    let dollarsIn = 0;
    let dollarsOut = 0;
    if (e.actionType === 'boost') {
      dollarsOut += parseFloat(e.debtChange);
      dollarsIn += e.collChange * e.price;
    }
    if (e.actionType === 'repay' || e.actionType === 'close') {
      dollarsIn += Math.abs(e.debtChange);
      dollarsOut += Math.abs(e.collChange * e.price);
    }
    if (e.actionType === 'create') {
      dollarsIn += e.collChange * e.price;
      dollarsOut += parseFloat(e.debtChange);
    }
    if (e.actionType === 'generate-dai') {
      dollarsOut += parseFloat(e.debtChange);
    }
    if (e.actionType === 'payback-dai') {
      dollarsIn += (-1) * e.debtChange;
    }
    if (e.actionType === 'withdraw-collateral') {
      dollarsOut += (-1) * e.collChange * e.price;
    }
    if (e.actionType === 'add-collateral') {
      // eslint-disable-next-line no-param-reassign
      if (liqHappened) liqHappened = false;
      else dollarsIn += e.collChange * e.price;
    }
    if (e.actionType === 'liquidation') {
      // eslint-disable-next-line no-param-reassign
      liqHappened = true;
    }
    return {
      dollarsIn,
      dollarsOut,
      liqHappened,
    };
  }

  parseData() {
    if (!this.props.txs.length || !this.props.cdpSimulationData) return;

    const actions = [...this.props.txs].reverse().filter((a) => a.after);
    if (actions.length === 0) {
      this.setState({ loading: false, noActions: true });
      return;
    }

    this.setState({
      chartOptions: null,
      loading: true,
    });

    const { cdpSimulationData } = this.props;

    const cdp = {
      debt: actions[0].after.debt,
      collateral: actions[0].after.collateral,
      block: actions[0].blockNumber,
    };
    const ratesReversed = cdpSimulationData.rates.sort((a, b) => b[0] - a[0]);

    const initialRate = ratesReversed.find((d) => d[0] <= cdp.block)[1];
    cdp.normalizedDebt = Dec(cdp.debt).times(1e27).div(initialRate);

    const cdpProfit = [];
    let nextTx = 1;
    const startBlock = actions[0].blockNumber;
    const cdpRatio = [[blockToDate(cdp.block).valueOf(), actions[0].after.ratio * 100]];
    let liqHappened = false;
    let { dollarsIn, dollarsOut } = this.parseAction(actions[0], liqHappened);
    cdpProfit.push([blockToDate(actions[0].blockNumber).valueOf(), this.cdpBalance(cdp, actions[0].price) - dollarsIn + dollarsOut]);
    cdpSimulationData.makerPrices.forEach(([block, price]) => {
      if (block < startBlock) return;
      for (; actions[nextTx] && actions[nextTx].blockNumber < block; nextTx++) {
        const action = actions[nextTx];
        if (action && action.blockNumber < block) {
          const { ratio } = action.after;
          const rate = ratesReversed.find((d) => d[0] <= action.blockNumber)[1];
          cdpRatio.push([blockToDate(action.blockNumber).valueOf(), 100 * ratio]);
          cdp.normalizedDebt = Dec(action.after.debt).times(1e27).div(rate).toString();
          cdp.debt = Dec(cdp.normalizedDebt).times(rate).div(1e27);
          cdp.collateral = action.after.collateral;
          const actionProfit = this.parseAction(action, liqHappened);
          dollarsIn += actionProfit.dollarsIn;
          dollarsOut += actionProfit.dollarsOut;
          liqHappened = actionProfit.liqHappened;
          cdpProfit.push([blockToDate(block).valueOf(), this.cdpBalance(cdp, action.price) - dollarsIn + dollarsOut]);
        }
      }
      const rate = ratesReversed.find((d) => d[0] <= block)[1];
      cdp.debt = Dec(cdp.normalizedDebt).times(rate).div(1e27);
      let ratio = (100 * cdp.collateral * price) / cdp.debt;
      if (Number.isNaN(ratio) || !Number.isFinite(ratio)) ratio = 0;
      cdpProfit.push([blockToDate(block).valueOf(), this.cdpBalance(cdp, price) - dollarsIn + dollarsOut]);
      cdpRatio.push([blockToDate(block).valueOf(), ratio]);
    });

    const ethPrice = cdpSimulationData.exchPrices.map(([block, price]) => [blockToDate(block).valueOf(), price]);

    const chartOptions = { ..._chartOptions };
    chartOptions.yAxis[0].title = { text: `${this.props.cdp.asset} Price` };
    // eslint-disable-next-line no-param-reassign
    chartOptions.yAxis.forEach((a) => { a.visible = window.innerWidth > 900; });
    chartOptions.series = [
      {
        type: 'line',
        name: `${this.props.cdp.asset} Price`,
        data: ethPrice,
        yAxis: 0,
        color: '#9B51E0',
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>$${this.y.toPrecision(5)}</b>`;
          },
        },
        showEmpty: false,
      },
      {
        type: 'line',
        name: 'CDP Ratio',
        id: 'cdpratio',
        data: cdpRatio,
        yAxis: 1,
        color: 'white',
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>${nFormatter(this.y)}%</b>`;
          },
        },
        showEmpty: false,
      },
      {
        type: 'line',
        name: 'CDP Profit',
        visible: true,
        data: cdpProfit,
        yAxis: 2,
        color: '#F2C94C',
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>$${nFormatter(this.y)}</b>`;
          },
        },
        showEmpty: false,
      },
      {
        type: 'flags',
        name: 'Actions',
        data: actions.map((a) => ({
          title: ' ',
          text: `${t(`maker_actions.${a.actionType}`)} (${nFormatter(a.collateral)} ${a.type}/${nFormatter(a.debt)} DAI)`,
          x: blockToDate(a.blockNumber).valueOf(),
          shape: `url(${a.actionType === 'Liquidation' ? graphPointRed : graphPoint})`,
        })),
        fillColor: '#37B06F',
        stroke: 'none',
        yAxis: 3,
        allowOverlapX: true,
        showEmpty: false,
      },
    ];
    chartOptions.yAxis[1].plotLines = [
      {
        dashStyle: 'dash',
        value: this.props.cdp.liqRatio * 100,
        zIndex: 0,
        color: '#F55858',
      },
    ];

    chartOptions.xAxis = {
      min: new Date().getTime() - (30 * 24 * 60 * 60 * 1000),
      max: new Date().getTime(),
    };

    this.setState({
      chartOptions,
      loading: false,
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="cdp-history-graph-wrapper flex">
        {loading && (<Loader />)}
        {this.state.noActions && (<div>No actions</div>)}
        {
          !loading && this.state.chartOptions && (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="stockChart"
              options={this.state.chartOptions}
            />
          )
        }
      </div>
    );
  }
}

CdpHistoryGraph.propTypes = {
  txs: PropTypes.array.isRequired,
  cdp: PropTypes.object.isRequired,
  cdpSimulationData: PropTypes.object,
  getSimulationData: PropTypes.func.isRequired,
};

CdpHistoryGraph.defaultProps = {
  cdpSimulationData: null,
};

const mapStateToProps = ({ general }, { cdp }) => ({
  cdpSimulationData: general.cdpSimulationData[cdp.ilk],
});
const mapDispatchToProps = {
  getSimulationData,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(CdpHistoryGraph), ErrorFallback);
