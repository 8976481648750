import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAssetInfo } from '@defisaver/tokens';
import t from 'translate';
import { getRatioForPrice, nFormatter, timestampToTime } from '../../../../services/utils';
import InfoItem from './InfoItem/InfoItem';

import '../../MakerManageInfoItems.scss';
import ChangeCaret from '../../../Decorative/ChangeCaret';


const MakerManageMcdInfoItems = ({ cdp, futureCdpAssetPrice, futureCdpAssetPriceTimestamp }) => (
  <div className="maker-manage-info-items-wrapper">
    <InfoItem
      icon={getAssetInfo(cdp.asset).icon}
      label={t('common.current_price')}
      value={cdp.price}
      prefixSymbol="$"
      additionalValue={futureCdpAssetPrice}
      additionalValueTooltip={t('common.next_price_explanation', { '%timestamp': timestampToTime(futureCdpAssetPriceTimestamp), '%price': futureCdpAssetPrice || 'Loading...', '%ratio': getRatioForPrice(cdp, futureCdpAssetPrice) })}
      additionalValueLabel="Next"
      additionalValuePrefixSymbol="$"
      additionalValueIcon={<ChangeCaret falling={futureCdpAssetPrice < cdp.price} />}
    />

    <InfoItem
      icon={getAssetInfo(cdp.asset).icon}
      label={t('common.liquidation_price')}
      value={cdp.liqPrice}
      prefixSymbol="$"
    />
  </div>
);

MakerManageMcdInfoItems.defaultProps = {
  futureCdpAssetPrice: 0,
  futureCdpAssetPriceTimestamp: 0,
};

MakerManageMcdInfoItems.propTypes = {
  cdp: PropTypes.object.isRequired,
  futureCdpAssetPrice: PropTypes.number,
  futureCdpAssetPriceTimestamp: PropTypes.number,
};

const mapStateToProps = ({ general }) => ({
  cdp: general.cdpData,
  futureCdpAssetPrice: general.globalInfo.tokenData?.[general.cdpData.ilk]?.futurePrice,
  futureCdpAssetPriceTimestamp: general.globalInfo.tokenData?.[general.cdpData.ilk]?.futurePriceUpdateTimestamp,
});

export default connect(mapStateToProps)(MakerManageMcdInfoItems);
