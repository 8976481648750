import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { changeSearchQuery } from '../../actions/apiActions';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import './CdpSearchBox.scss';

class CdpSearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.queryApi = this.queryApi.bind(this);
    this.debouncedQueryApi = debounce(this.queryApi, 500);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.clear = this.clear.bind(this);
  }

  queryApi() {
    const { searchQuery } = this.props;
    if (!searchQuery) {
      this.props.history.push('/');
    } else if (searchQuery.length <= 6 && searchQuery.match(/^\d+$/)) {
      this.props.history.push(`/cdp/${searchQuery}`); // CDP query
    } else if (searchQuery.length === 42 && searchQuery.substr(0, 2).toLowerCase() === '0x') {
      this.props.history.push(`/address/${searchQuery.toLowerCase()}`); // address query
    } else if (searchQuery.length === 66 && searchQuery.substr(0, 2).toLowerCase() === '0x') {
      this.props.history.push(`/tx/${searchQuery.toLowerCase()}`); // tx query
    } else if (searchQuery.match(/^(\w+\.)+\w+$/)) {
      this.props.history.push(`/address/${searchQuery.toLowerCase()}`); // ENS query
    }
  }

  handleChange(e) {
    this.props.changeSearchQuery(e.target.value.replace(/[^\d\w.]/g, ''));
    this.debouncedQueryApi();
  }

  handleKey(e) {
    if (e.key === 'Escape') this.clear();
    if (e.key === 'Enter') this.queryApi();
  }

  handleBlur() {
    if (!this.props.searchQuery) this.clear();
  }

  clear() {
    if (document.location.pathname !== '/') this.props.history.push('/');
  }

  render() {
    const { searchDataLoading, searchQuery } = this.props;

    return (
      <div className="cdp-search-box-wrapper">
        <input
          value={searchQuery}
          autoFocus
          type="text"
          placeholder="Search"
          onChange={this.handleChange}
          onKeyDown={this.handleKey}
          onBlur={this.handleBlur}
        />
        {searchDataLoading && <div className="loading" />}
        {
          !searchDataLoading
          && searchQuery
          && <button type="button" aria-label="Clear" className="clear" onClick={this.clear} />
        }
      </div>
    );
  }
}

CdpSearchBox.propTypes = {
  history: PropTypes.object.isRequired,
  changeSearchQuery: PropTypes.func.isRequired,
  searchDataLoading: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

CdpSearchBox.defaultProps = {};

const mapStateToProps = ({ general }) => ({
  searchDataLoading: general.searchDataLoading,
  searchQuery: general.searchQuery,
});

const mapDispatchToProps = {
  changeSearchQuery,
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(withRouter(CdpSearchBox)), ErrorFallback);
