import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './NotFound.scss';
import cdpDude from '../MakerManage/cdp-dude.png';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { type, searchQuery } = this.props;
    return (
      <div className="not-found-wrapper">
        <div>
          <h1>
            { type === 'cdp' && (`CDP #${searchQuery} not found`) }
            { type === 'tx' && ('Transaction not supported') }
            { type === 'address' && ('Address not found') }
          </h1>
          {
            (type === 'tx' || type === 'address') && (<h2>{searchQuery}</h2>)
          }
          <p>
            { type === 'cdp' && (
              <>
                We could not find the CDP you were looking for! :(
                <br />
                Please try searching for a different CDP ID or click below to create a new CDP or manage your existing one.
              </>
            )}
            { type === 'tx' && (
              <>
                Currently, only transactions affecting multi-collateral Vaults and failed transactions are supported.
              </>
            )}
          </p>
          <a
            href="https://defisaver.com/makerdao/create-cdp"
            target="_blank"
            rel="noopener noreferrer"
            className="button green"
          >
            Create CDP
          </a>
        </div>
        <div />
      </div>
    );
  }
}

NotFound.propTypes = {
  type: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
};


const mapStateToProps = ({ general }) => ({
  searchQuery: general.searchQuery,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
