import React from 'react';
import PropTypes from 'prop-types';

import './RatioCircle.scss';

const RatioCircle = ({ color }) => (
  <div className="ratio-circle" style={{ backgroundColor: color }} />
);

RatioCircle.defaultProps = {
  color: '#61717E',
};

RatioCircle.propTypes = {
  color: PropTypes.string,
};

export default RatioCircle;
