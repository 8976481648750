import React from 'react';
import t from 'translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  nFormatter, blockToDate, parseTimestamp, getColorForRatio,
} from '../../../services/utils';
import TooltipWrapper from '../../Decorative/TooltipWrapper/TooltipWrapper';

import './AutomationHistory.scss';
import Arrow from '../../Decorative/Arrow';
import { getCSV } from '../../../services/simulationService';

const AutomationHistory = ({ history, cdp }) => (
  <div className="automation-history-wrapper">
    <div className="width-wrapper">
      {
        history.length > 0 && (
          <div className="styled-table-wrapper">
            <table className="history-table">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Coll. Change</th>
                  <th>Debt Change</th>
                  <th>{ t('maker.ratio_change') }</th>
                  <th>Gas + Service Fee</th>
                  <th>{cdp.asset} Price</th>
                  <th>{ t('common.date') }</th>
                </tr>
              </thead>
              <tbody>
                {
                  history.map(({
                    name, ratioBefore, ratioAfter, collAfter, debtAfter, profitAfter, blockNumber, amount,
                    collChange, debtChange, serviceFeeUsd, gasFeeUsd, makerPrice, exchangePrice,
                  }) => (
                    <tr key={`${name}-${blockNumber}-${ratioAfter}`}>
                      <td><div className={`action ${name.toLowerCase()}`}>{ name }</div></td>
                      <td>
                        <TooltipWrapper title={collChange}>{ nFormatter(collChange, 2) } {cdp.asset}</TooltipWrapper>
                      </td>
                      <td>
                        <TooltipWrapper title={debtChange}>{ nFormatter(debtChange, 2) } DAI</TooltipWrapper>
                      </td>
                      <td>
                        <span style={{ color: getColorForRatio(ratioBefore, 150) }}>{nFormatter(ratioBefore)}%</span>
                        {' '}<Arrow orientation="right" size={12} color="white" />{' '}
                        <span style={{ color: getColorForRatio(ratioAfter, 150) }}>{nFormatter(ratioAfter)}%</span>
                      </td>
                      <td>
                        ${nFormatter(gasFeeUsd)} + ${nFormatter(serviceFeeUsd)}
                      </td>
                      <td>
                        ${ nFormatter(exchangePrice, 2) }
                      </td>
                      <td>{parseTimestamp(blockToDate(blockNumber))}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div className="Flex FlexEnd MarginTop10">
              <a
                href={URL.createObjectURL(new Blob([getCSV(history)], { type: 'application/octet-stream' }))}
                download={`Automation ${(new Date()).toISOString()}.csv`}
              >Export CSV
              </a>
            </div>
          </div>
        )
      }
    </div>
  </div>
);

AutomationHistory.propTypes = {
  history: PropTypes.array.isRequired,
  cdp: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AutomationHistory);
